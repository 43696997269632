import './App.css';
import {BrowserRouter as  Router, Route, Routes} from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import {authenticateUser} from "./Components/Authentication/Auth";
import {useEffect} from "react";
import FlightResult from "./Components/BookingForm/FlightResults/FlightResult";
import FlightDetails from "./Components/BookingForm/FlightDetails/FlightDetails";
import Service from "./Components/Service";
import PayNow from "./Components/PayNow/PayNow";
import PaymentSucess from "./Components/PayNow/PaymentSucess";
import Login from "./Components/Login/Login";
// import Hotels from "./Components/Hotels";
import HotelBookingForm from "./Components/Hotels/HotelBookingForm";
function App() {

    useEffect(() => {
        authenticateUser();
    }, []);
  return (
      <Router>
        <div>
          {/* Define routes here */}
          {/*<NavBar />*/}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/flight-results" element={<FlightResult />} />
            <Route path="/flight-details" element={<FlightDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/paynow" element={<PayNow />} />
            <Route path="/payment/success" element={<PaymentSucess />} />
            <Route path="/login" element={<Login />} />
            <Route path="/hotels" element={<Home />} />
          </Routes>

        </div>
      </Router>
  );
}

export default App;
