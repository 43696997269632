import useExternalScripts from "../../hooks/useExternalScripts";

const FlightDetailsUI = ({
                             flight,
                             handleSubmit,
                             passengerDetails,
                             isSubmitted,
                             isEditing,
                             handlePassengerChange,
                             handleSubmitPassengers,
                             handleAddPassenger,
                             handleSave,
                             handleEdit,
                             submittedPassengers,

                         }) => {

    // Safely access flight details
    const origin = flight.Segments[0][0].Origin.Airport.CityCode || "Unknown";
    const fromCity = flight.Segments[0][0].Origin.Airport.CityName || "City Name";
    const departureTime = flight.Segments[0][0].Origin.DepTime || "00:00 PM";
    const departureAirportName = flight.Segments[0][0].Origin.Airport.AirportName || "Airport Name";
    const destination = flight.Segments[0][0].Destination.Airport.CityCode || "Unknown";
    const toCity = flight.Segments[0][0].Destination.Airport.CityName || "City Name";
    const arrivalTime = flight.Segments[0][0].Destination.ArrTime || "00:00 PM";
    const arrivalDate = flight.Segments[0][0].Destination.Airport.AirportName || "Airport Name";
    const duration = flight.Segments[0][0].Duration + " minutes" || "000 mins";
    const flightNumber = flight.Segments[0][0].Airline.FlightNumber || "AI123";
    const airline = flight.Segments[0][0].Airline.AirlineName || "Air India";
    const cabinBaggage = flight.Segments[0][0].CabinBaggage || "0 kg";
    const checkinBaggage = flight.Segments[0][0].Baggage || "00 kg";
    const flightClass = flight.Segments[0][0].CabinClass || "CabinClass";
    const publishedFare = flight.Fare.PublishedFare;
    const tax = flight.Fare.Tax;
    const YQTax = flight.Fare.YQTax;
    const isPanRequired = flight.IsPanRequiredAtBook || false;
    const isPassportRequired = flight.IsPassportRequiredAtBook || false;
    const isLcc = flight.IsLCC

    useExternalScripts()



    return (
        <>
            <div className="container">
                <div
                    className="mx-auto text-center mb-5"
                    style={{ maxWidth: "900px", marginTop: "15px" }}
                >
                    <h5 className="section-title px-3">Check Out</h5>
                    <h1 className="mb-0">Add Passenger Details</h1>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {/* Flight Details Section */}
                        <div className="col-md-8 col-sm-12 mb-3">
                            <div className="details-box p-3 bg-white border rounded mb-3">
                                <h4>Flight Details</h4>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p style={{fontSize: "30px"}}>{origin}</p>
                                        <p style={{fontSize: "18px"}}>{fromCity}</p>
                                        <p className="text-muted">
                                            {departureTime} <br/> {departureAirportName}
                                        </p>
                                    </div>
                                    <span>--------------------</span>
                                    <i className="fas fa-plane" style={{fontSize: "35px"}}></i>
                                    <span>--------------------</span>
                                    <div>
                                        <p style={{fontSize: "30px"}}>{destination}</p>
                                        <p style={{fontSize: "18px"}}>{toCity}</p>
                                        <p className="text-muted">
                                            {arrivalTime} <br/> {arrivalDate}
                                        </p>
                                    </div>
                                </div>
                                <table className="table mt-3">
                                    <tbody>
                                    <tr>
                                        <td>Duration</td>
                                        <td>{duration}</td>
                                    </tr>
                                    <tr>
                                        <td>Flight Number</td>
                                        <td>{flightNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Airline</td>
                                        <td>{airline}</td>
                                    </tr>
                                    <tr>
                                        <td>Cabin Baggage</td>
                                        <td>{cabinBaggage}</td>
                                    </tr>
                                    <tr>
                                        <td>Check-in Baggage</td>
                                        <td>{checkinBaggage}</td>
                                    </tr>
                                    <tr>
                                        <td>Class</td>
                                        <td>{flightClass}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* Passenger Form Section */}
                            <div className="container" style={{maxWidth: "1200px", margin: "auto"}}>
                                <div className="passenger-form p-3 bg-white border rounded"
                                     style={{overflow: "hidden"}}>


                                    {!isSubmitted && (
                                        <form onSubmit={isEditing !== null ? handleSave : handleAddPassenger}>
                                            <h4>Passenger Details</h4>
                                            <div className="form-group">
                                                <label>Title</label>
                                                <select
                                                    name="title"
                                                    value={passengerDetails.title}
                                                    onChange={handlePassengerChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Mr">Mr.</option>
                                                    <option value="Ms">Ms.</option>
                                                    <option value="Mrs">Mrs.</option>
                                                    <option value="Dr">Dr.</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    value={passengerDetails.firstName}
                                                    onChange={handlePassengerChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    value={passengerDetails.lastName}
                                                    onChange={handlePassengerChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Gender</label>
                                                <select
                                                    name="gender"
                                                    value={passengerDetails.gender}
                                                    onChange={handlePassengerChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    value={passengerDetails.phone}
                                                    onChange={handlePassengerChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={passengerDetails.email}
                                                    onChange={handlePassengerChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Date of Birth</label>
                                                <input
                                                    type="date"
                                                    name="dateOfBirth"
                                                    value={passengerDetails.dateOfBirth}
                                                    onChange={handlePassengerChange}
                                                />
                                            </div>
                                            {isPanRequired && (
                                                <div className="form-group">
                                                    <label>PAN</label>
                                                    <input
                                                        type="text"
                                                        name="pan"
                                                        value={passengerDetails.pan}
                                                        onChange={handlePassengerChange}
                                                    />
                                                </div>
                                            )}
                                            {isPassportRequired && (
                                                <>
                                                    <div className="form-group">
                                                        <label>Passport</label>
                                                        <input
                                                            type="text"
                                                            name="passport"
                                                            value={passengerDetails.passport}
                                                            onChange={handlePassengerChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Passport Expiry</label>
                                                        <input
                                                            type="date"
                                                            name="passportExpiry"
                                                            value={passengerDetails.passportExpiry}
                                                            onChange={handlePassengerChange}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {isEditing !== null ? (
                                                <button type="submit" className="btn btn-success">
                                                    Save
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn btn-primary
                                                ">
                                                    Add Passenger
                                                </button>
                                            )}
                                        </form>
                                    )}

                                    <div className="table mt-3">

                                        <tbody>
                                        {submittedPassengers.map((passenger, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p>
                                                        Name: {passenger.title} {passenger.firstName} {passenger.lastName} ||
                                                        Gender: {passenger.gender} ||
                                                        Date Of Birth: {passenger.dateOfBirth}
                                                    </p>
                                                    <p>
                                                        Phone: {passenger.phone} |
                                                        Email: {passenger.email}
                                                    </p>
                                                    {passenger.pan || passenger.passport || passenger.passportExpiry ? (
                                                        <p>
                                                            {passenger.pan ? `PAN: ${passenger.pan} | ` : ''}
                                                            {passenger.passport ? `Passport: ${passenger.passport} | ` : ''}
                                                            {passenger.passportExpiry ? `Passport Expiry: ${passenger.passportExpiry}` : ''}
                                                        </p>
                                                    ) : null}
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => handleEdit(index)}
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </div>

                                    {submittedPassengers.length > 0 && (
                                        <button
                                            className="btn btn-success"
                                            onClick={handleSubmitPassengers}
                                        >
                                            Submit All Passengers
                                        </button>
                                    )}

                                </div>
                            </div>
                        </div>

                        {/* Fare Details Section */}
                        <div className="col-md-4 col-sm-12 mb-3">
                            <div className="fare-box p-3 bg-light border rounded">
                                <h4>Fare Details</h4>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Base Fare</td>
                                        <td>
                                            <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                                            {publishedFare}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td>
                                            <i className="fa-solid fa-indian-rupee-sign"></i> {tax}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Convenience Fee</td>
                                        <td>
                                            <i className="fa-solid fa-indian-rupee-sign"></i> {YQTax}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Fare</td>
                                        <td>
                                            <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                                            {Number(publishedFare) + Number(tax) + Number(YQTax)}
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan="2" className="text-center">
                                            <button
                                                className="btn btn-success mt-3"
                                                onClick={handleSubmit}
                                            >
                                                {isLcc ?   "Proceed to Ticket" : "Proceed to Book"}
                                            </button>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default FlightDetailsUI;
