import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FlightDetails.css';
import {BookFlight, getFareQuote, initiatePayment, TicketFlight} from "../Service/BookingService";
import Header from "../../Header";
import FlightDetailsUI from "./FlightDetailsUI";
import Footer from "../../Footer";

const FlightDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const flight = location.state?.flight || {};
    console.log("Flight full Response", flight);

    // Store flight result index in local storage
    useEffect(() => {
        if (flight.ResultIndex) {
            localStorage.setItem('resultIndex', flight.ResultIndex);
        }
    }, [flight]);

    // State for fare details fetched from API
    const [fareDetails, setFareDetails] = useState(null);
    // const [passengers, setPassengers] = useState([{ title: '', firstName: '', lastName: '', dob: '', gender: '', passport: '', phone: '', email: '', paxType: '' }]);
    const [errorMessage, setErrorMessage] = useState('');
    const [traceId, setTraceId] = useState('');

    // Fetch fare details from API
    const fetchFareDetails = async () => {
        try {
            const fareQuote = await getFareQuote(flight);
            if (fareQuote?.Response?.Results?.Fare) {
                const fare = fareQuote.Response.Results.Fare;
                setFareDetails({
                    Currency: fare.Currency || 'INR',
                    BaseFare: fare.BaseFare || 0,
                    Tax: fare.Tax || 0,
                    YQTax: fare.YQTax || 0,
                    AdditionalTxnFeePub: fare.AdditionalTxnFeePub || 0,
                    AdditionalTxnFeeOfrd: fare.AdditionalTxnFeeOfrd || 0,
                    Discount: fare.Discount || 0,
                    PublishedFare: fare.PublishedFare || 0,
                    OfferedFare: fare.OfferedFare || 0,
                    TdsOnCommission: fare.TdsOnCommission || 0,
                    TdsOnPLB: fare.TdsOnPLB || 0,
                    TdsOnIncentive: fare.TdsOnIncentive || 0,
                    ServiceFee: fare.ServiceFee || 0,
                });
            }
        } catch (error) {
            console.error("Error fetching fare details:", error);
        }
    };

    useEffect(() => {
        if (flight) {
            fetchFareDetails();
        }
    }, [flight]);

    // Handle proceed to payment
    const handleProceedToPayment = async () => {
        const resultIndex = localStorage.getItem('resultIndex');
        const tokenId = localStorage.getItem('tokenId');
        const traceId = localStorage.getItem('traceId');

        if (!fareDetails) {
            console.error("Fare details are not available for booking");
            return;
        }

        // Construct request payload
        const requestPayload = {
            ResultIndex: resultIndex,
            Passengers: submittedPassengers.map((passenger, index) => ({
                Title: passenger.title,
                FirstName: passenger.firstName,
                LastName: passenger.lastName,
                PaxType: 1,
                DateOfBirth: passenger.dateOfBirth,
                Gender: passenger.gender,
                PassportNo: passenger.passport,
                PassportExpiry: '',
                AddressLine1: 'Delhi',
                City: 'Gurgaon',
                CountryCode: 'IN',
                CellCountryCode: '+91',
                ContactNo: passenger.phone,
                Nationality: 'IN',
                Email: passenger.email,
                IsLeadPax: index === 0,
                Fare: fareDetails,
                FFAirlineCode:"",
                FFNumber:"",
                GSTCompanyAddress:"7th floor, 704 Palm Court, Sector -16, \n" +
                    "Mehrauli Gurugram Road,Gurugram, \n" +
                    "Haryana PIN Code: 122007" +
                    "Mehrauli Gurugram Road,Gurugram",
                GSTCompanyContactNumber: "+91 124-4956534",
                GSTCompanyName: "FAREFUELS HOLIDAY PVT LTD",
                GSTNumber: "06AAFCF8772N1ZY",
                GSTCompanyEmail: "contact@farefuels.com"
            })),
            EndUserIp: "192.168.11.58",
            TokenId: tokenId,
            TraceId: traceId,
        };

        console.log("Request Payload", requestPayload);

        try {
            const paymentPayload = {
                amount: fareDetails.PublishedFare, // Use PublishedFare for the payment amount
                currency: fareDetails.Currency || 'INR', // Default to INR if currency is not available
                // Add other necessary payment details (e.g., Merchant ID, Order ID, etc.)
            };

            if (flight.IsLCC === true) {
                await initiatePayment(paymentPayload);
                // No need for further action, as the form will redirect to the payment page
            } else {
                const bookingResponse = await BookFlight(requestPayload);
                console.log("Final Hold Response", bookingResponse);
            }
        } catch (error) {
            console.error('Error in payment or booking process:', error);
            setErrorMessage('Booking or payment failed. Please try again later.');
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log("Submitted Passenger Details", passengers);
        await handleProceedToPayment();
    };

    // Add a new passenger
    const addPassenger = () => {
        setPassengers([...passengers, { title: '', firstName: '', lastName: '', dob: '', gender: '', passport: '', phone: '', email: '', paxType: '' }]);
    };

    const [passengerDetails, setPassengerDetails] = useState({
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        phone: "",
        email: "",
        pan: "",
        passport: "",
        passportExpiry: "",
        dateOfBirth: "",
    });

    const handleAddPassenger = (e) => {
        e.preventDefault();
        setSubmittedPassengers([...submittedPassengers, passengerDetails]);
        setPassengerDetails({
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            phone: "",
            email: "",
            pan: "",
            passport: "",
            passportExpiry: "",
            dateOfBirth: "",
        });
    };

    const handleEdit = (index) => {
        setIsEditing(index);
        setPassengerDetails(submittedPassengers[index]);
    };

    const handleSave = (e) => {
        e.preventDefault();
        setSubmittedPassengers(
            submittedPassengers.map((passenger, i) =>
                i === isEditing ? passengerDetails : passenger
            )
        );
        setIsEditing(null);
        setPassengerDetails({
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            phone: "",
            email: "",
            pan: "",
            passport: "",
            passportExpiry: "",
            dateOfBirth: "",
        });
    };

    const [passengers, setPassengers] = useState([{ title: '', firstName: '', lastName: '', dob: '', gender: '', passport: '', phone: '', email: '', paxType: '' }]);


    const handleSubmitPassengers = (newPassengers) => {
        setPassengers(newPassengers);  // Update passengers state with the new passengers from FlightDetailsUI
        console.log("Updated Passenger Details:", newPassengers);
        setIsSubmitted(true);  // Set submission status to true
    };

    const [submittedPassengers, setSubmittedPassengers] = useState([]);
    const [isEditing, setIsEditing] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handlePassengerChange = (e) => {
        setPassengerDetails({ ...passengerDetails, [e.target.name]: e.target.value });
        const { name, value } = e.target;
        setPassengerDetails({...passengerDetails, [name]: value,});
    };

    return (
        <>
            <Header />
            <FlightDetailsUI
                flight={flight}
                passengers={passengers}
                handlePassengerChange={handlePassengerChange}
                handleSubmit={handleSubmit}
                addPassenger={addPassenger}
                passengerDetails={passengerDetails}
                setPassengerDetails={setPassengerDetails}
                isSubmitted={isSubmitted}
                isEditing={isEditing}
                handleSubmitPassengers={handleSubmitPassengers}
                handleAddPassenger={handleAddPassenger}
                handleSave={handleSave}
                handleEdit={handleEdit}
                submittedPassengers={submittedPassengers}
            />
            {errorMessage && (
                <div>
                    <h3>Error: {errorMessage}</h3>
                    <p>Session ID: {traceId}</p>
                </div>
            )}
            <Footer />
        </>
    );
};

export default FlightDetails;
