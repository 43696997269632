import React, { useState, useEffect } from "react";
import "../assets/css/FormComponent.css";
import { getAirport, searchFlights } from "./Service/BookingService";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import CustomSelect from "./CustomSelect"; // Import the CustomSelect component

const BookingForm = () => {
    const navigate = useNavigate();

    const today = new Date().toISOString().split('T')[0];
    // State to hold form data
    const [formData, setFormData] = useState({
        origin: '',
        destination: '',
        departureDate: '',
        adults: 1,
        children: 0,
        travelClass: '1',
        airline: '',
        journeyType: '1',
    });

    // State to manage loading states
    const [loading, setLoading] = useState(false);
    const [airports, setAirports] = useState([]);
    const [loadingAirports, setLoadingAirports] = useState(true);

    // Fetch airport data on component mount
    useEffect(() => {
        const fetchAirports = async () => {
            try {
                const airportData = await getAirport();
                setAirports(airportData);
            } catch (error) {
                console.error('Error fetching airport data:', error);
            } finally {
                setLoadingAirports(false);
            }
        };

        fetchAirports();
    }, []);

    // Handle input changes
    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Prepare request data
        const requestData = {
            EndUserIp: "192.168.10.10",
            TokenId: localStorage.getItem('tokenId'),
            AdultCount: parseInt(formData.adults),
            ChildCount: parseInt(formData.children),
            InfantCount: 0,
            DirectFlight: false,
            OneStopFlight: false,
            JourneyType: formData.journeyType,
            PreferredAirlines: formData.airline || null,
            Segments: [
                {
                    Origin: formData.origin,
                    Destination: formData.destination,
                    FlightCabinClass: formData.travelClass,
                    PreferredDepartureTime: `${formData.departureDate}T00:00:00`,
                    PreferredArrivalTime: `${formData.departureDate}T00:00:00`,
                },
            ],
            Sources: null,
        };

        console.log('Request Data:', requestData);
        setLoading(true);

        searchFlights(requestData)
            .then((result) => {
                console.log('API Response:', result);
                navigate('/flight-results', { state: { flights: result } });
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="booking-sec">
            <div className="container">
                <div className="booking-form ajax-contact">
                    <div className="form-container">
                        {loading && <Loader />}
                        <form className="responsive-form" onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <CustomSelect
                                        options={airports}
                                        value={formData.origin}
                                        onChange={(value) => handleChange('origin', value)}
                                        label="Origin"
                                        isLoading={loadingAirports}
                                    />
                                </div>
                                <div className="form-group">
                                    <CustomSelect
                                        options={airports}
                                        value={formData.destination}
                                        onChange={(value) => handleChange('destination', value)}
                                        label="Destination"
                                        isLoading={loadingAirports}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="departureDate">Departure Date</label>
                                    <input
                                        type="date"
                                        id="departureDate"
                                        name="departureDate"
                                        value={formData.departureDate}
                                        onChange={(e) => handleChange('departureDate', e.target.value)}
                                        required
                                        min={today}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="adults">Adults</label>
                                    <input
                                        type="number"
                                        id="adults"
                                        name="adults"
                                        value={formData.adults}
                                        onChange={(e) => handleChange('adults', e.target.value)}
                                        placeholder="Number of Adults"
                                        min="1"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="children">Children</label>
                                    <input
                                        type="number"
                                        id="children"
                                        name="children"
                                        value={formData.children}
                                        onChange={(e) => handleChange('children', e.target.value)}
                                        placeholder="Number of Children"
                                        min="0"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="travelClass">Class</label>
                                    <select
                                        id="travelClass"
                                        name="travelClass"
                                        value={formData.travelClass}
                                        onChange={(e) => handleChange('travelClass', e.target.value)}
                                        required
                                    >
                                        <option value="1">Economy/Premium</option>
                                        <option value="2">Economy</option>
                                        <option value="3">PremiumEconomy</option>
                                        <option value="4">Business</option>
                                        <option value="5">PremiumBusiness</option>
                                        <option value="6">First Class</option>

                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="airline">Preferred Airline</label>
                                    <input
                                        type="text"
                                        id="airline"
                                        name="airline"
                                        value={formData.airline}
                                        onChange={(e) => handleChange('airline', e.target.value)}
                                        placeholder="Preferred Airline"
                                    />
                                </div>
                            </div>
                            <div className="form-button col-md-12 col-lg-auto">
                                <button className="th-btn" type="submit">
                                    <img src="/assets/img/icon/search.svg" alt="" /> Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingForm;
