import React, { useState } from 'react';
import './Paynow.css';
import { initiatePayment } from "../BookingForm/Service/BookingService";
import Header from "../Header";
import Footer from "../Footer";
import useExternalScripts from "../hooks/useExternalScripts"; // Assuming this is the API call service

const PayNow = (props) => {
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('INR'); // Default currency
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle error messages

    useExternalScripts()
    // Billing Details
    const [billingName, setBillingName] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingState, setBillingState] = useState('');
    const [billingZip, setBillingZip] = useState('');
    const [billingCountry, setBillingCountry] = useState('India');
    const [billingTel, setBillingTel] = useState('');
    const [billingEmail, setBillingEmail] = useState('');

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setLoading(true); // Set loading state to true while the payment is being processed
        setError(null); // Clear any previous errors

        const paymentData = {
            amount,
            currency,
            billingName,
            billingAddress,
            billingCity,
            billingState,
            billingZip,
            billingCountry,
            billingTel,
            billingEmail,
        };

        try {
            // Call the initiatePayment function with the payment data
            const response = await initiatePayment(paymentData);

            // Handle success based on response
            if (response && response.status === 'success') {
                // Redirect to the success URL
                window.location.href = 'https://farefuelsholidays.com/paymentSuccess';
            } else {
                setError('Payment failed. Please try again.'); // Set error message if payment failed
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            setError('There was an issue processing your payment. Please try again.');
        } finally {
            setLoading(false); // Stop loading spinner once request completes
        }
    };

    return (
        <>
        <Header/>
        <div className="payNow-container">
            <h3>Pay Now</h3>
            <h4> FAREFUELS HOLIDAY PVT LTD</h4>
            <p>KOTAK MAHINDRA BANK
                A/C NO. 8149402488
                IFSC :- KKBK0004605
                ACCOUNT TYPE:- CURRENT
                BRANCH:-CONNAUGHT PLACE DELHI</p>
            <p>
                AU SMALL FINANCE BANK
                A/C 240221066402250
                IFSC :-AUBL0002106
                BRANCH:-Urban Estate Gurgaon
            </p>

            {/*/!* Loading Spinner *!/*/}
            {/*{loading && <div className="loading-spinner">Processing payment...</div>}*/}
            
            {/*/!* Error Message *!/*/}
            {/*{error && <div className="error-message">{error}</div>}*/}

            <form onSubmit={handleSubmit} className="payment-form">
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="amount">Enter Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter amount"
                            required
                            className="input-field"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="currency">Select Currency:</label>
                        <select
                            id="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            required
                            className="input-field"
                        >
                            <option value="USD">USD - US Dollar</option>
                            <option value="EUR">EUR - Euro</option>
                            <option value="INR">INR - Indian Rupee</option>
                            <option value="GBP">GBP - British Pound</option>
                            <option value="JPY">JPY - Japanese Yen</option>
                        </select>
                    </div>
                </div>

                {/*<div className="form-row">*/}
                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingName">Billing Name:</label>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            id="billingName"*/}
                {/*            value={billingName}*/}
                {/*            onChange={(e) => setBillingName(e.target.value)}*/}
                {/*            placeholder="Enter billing name"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingAddress">Billing Address:</label>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            id="billingAddress"*/}
                {/*            value={billingAddress}*/}
                {/*            onChange={(e) => setBillingAddress(e.target.value)}*/}
                {/*            placeholder="Enter billing address"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="form-row">*/}
                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingCity">Billing City:</label>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            id="billingCity"*/}
                {/*            value={billingCity}*/}
                {/*            onChange={(e) => setBillingCity(e.target.value)}*/}
                {/*            placeholder="Enter billing city"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingState">Billing State:</label>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            id="billingState"*/}
                {/*            value={billingState}*/}
                {/*            onChange={(e) => setBillingState(e.target.value)}*/}
                {/*            placeholder="Enter billing state"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="form-row">*/}
                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingZip">Billing Zip:</label>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            id="billingZip"*/}
                {/*            value={billingZip}*/}
                {/*            onChange={(e) => setBillingZip(e.target.value)}*/}
                {/*            placeholder="Enter billing zip code"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingCountry">Billing Country:</label>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            id="billingCountry"*/}
                {/*            value={billingCountry}*/}
                {/*            onChange={(e) => setBillingCountry(e.target.value)}*/}
                {/*            placeholder="Enter billing country"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="form-row">*/}
                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingTel">Billing Telephone:</label>*/}
                {/*        <input*/}
                {/*            type="tel"*/}
                {/*            id="billingTel"*/}
                {/*            value={billingTel}*/}
                {/*            onChange={(e) => setBillingTel(e.target.value)}*/}
                {/*            placeholder="Enter billing telephone"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="billingEmail">Billing Email:</label>*/}
                {/*        <input*/}
                {/*            type="email"*/}
                {/*            id="billingEmail"*/}
                {/*            value={billingEmail}*/}
                {/*            onChange={(e) => setBillingEmail(e.target.value)}*/}
                {/*            placeholder="Enter billing email"*/}
                {/*            required*/}
                {/*            className="input-field"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit Payment'}
                </button>
            </form>
        </div>
            <Footer/>
        </>
    );
};

export default PayNow;
