import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import BookingForm from "./BookingForm/BookingForm";
import {Link, useLocation} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import useExternalScripts from "./hooks/useExternalScripts";
import HotelBookingForm from "./Hotels/HotelBookingForm";


const Home = () => {
    useExternalScripts()

    // useEffect(() => {
    //     // Dynamically load external scripts
    //     const loadScript = (src) => {
    //         const script = document.createElement('script');
    //         script.src = src;
    //         script.async = true;
    //         document.body.appendChild(script);
    //         return () => {
    //             document.body.removeChild(script);
    //         };
    //     };
    //
    //     const scripts = [
    //         '/assets/js/vendor/jquery-3.6.0.min.js',
    //         '/assets/js/swiper-bundle.min.js',
    //         '/assets/js/bootstrap.min.js',
    //         '/assets/js/jquery.magnific-popup.min.js',
    //         '/assets/js/jquery.counterup.min.js',
    //         '/assets/js/jquery-ui.min.js',
    //         '/assets/js/imagesloaded.pkgd.min.js',
    //         '/assets/js/isotope.pkgd.min.js',
    //         '/assets/js/gsap.min.js',
    //         '/assets/js/circle-progress.js',
    //         '/assets/js/matter.min.js',
    //         '/assets/js/matterjs-custom.js',
    //         '/assets/js/nice-select.min.js',
    //         '/assets/js/main.js',
    //     ];
    //
    //     // Load all scripts
    //     scripts.forEach((script) => loadScript(script));
    // }, []);

    const location = useLocation(); // Get current URL path from React Router

    return (
        <div>
            <Helmet>
                {/* Mirrored from html.themeholy.com/tourm/demo/index.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:14:22 GMT */}
                <meta charSet="utf-8"/>
                <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
                <title>
                    Tourm - Travel &amp; Tour Booking Agency HTML Template - Home Travel
                </title>
                <meta name="author" content="Tourm"/>
                <meta
                    name="description"
                    content="Tourm - Travel & Tour Booking Agency HTML Template "
                />
                <meta
                    name="keywords"
                    content="Tourm - Travel & Tour Booking Agency HTML Template "
                />
                <meta name="robots" content="INDEX,FOLLOW"/>
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no"
                />


                {/* Scripts */}
                {/*<script src="/assets/js/vendor/jquery-3.6.0.min.js"></script>*/}
                {/*<script src="/assets/js/swiper-bundle.min.js"></script>*/}
                {/*<script src="/assets/js/bootstrap.min.js"></script>*/}
                {/*<script src="/assets/js/jquery.magnific-popup.min.js"></script>*/}
                {/*<script src="/assets/js/jquery.counterup.min.js"></script>*/}
                {/*<script src="/assets/js/jquery-ui.min.js"></script>*/}
                {/*<script src="/assets/js/imagesloaded.pkgd.min.js"></script>*/}
                {/*<script src="/assets/js/isotope.pkgd.min.js"></script>*/}
                {/*<script src="/assets/js/gsap.min.js"></script>*/}
                {/*<script src="/assets/js/circle-progress.js"></script>*/}
                {/*<script src="/assets/js/matter.min.js"></script>*/}
                {/*<script src="/assets/js/matterjs-custom.js"></script>*/}
                {/*<script src="/assets/js/nice-select.min.js"></script>*/}
                {/*<script src="/assets/js/main.js"></script>*/}


            </Helmet>
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/assets/img/favicons/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/assets/img/favicons/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/assets/img/favicons/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/assets/img/favicons/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/assets/img/favicons/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/assets/img/favicons/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/assets/img/favicons/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/assets/img/favicons/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/assets/img/favicons/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/assets/img/favicons/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/assets/img/favicons/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/assets/img/favicons/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/assets/img/favicons/favicon-16x16.png"
            />
            <link rel="manifest" href="/assets/img/favicons/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="assets/img/favicons/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montez&display=swap"
                rel="stylesheet"/>
            {/*<link rel="stylesheet" href="assets/css/bootstrap.min.css" />*/}
            {/*<link rel="stylesheet" href="assets/css/fontawesome.min.css" />*/}
            {/*<link rel="stylesheet" href="assets/css/magnific-popup.min.css" />*/}
            {/*<link rel="stylesheet" href="assets/css/swiper-bundle.min.css" />*/}
            {/*<link rel="stylesheet" href="assets/css/style.css" />*/}
            <div className="magic-cursor relative z-10">
                <div className="cursor" />
                <div className="cursor-follower" />
            </div>
            <div className="color-scheme-wrap active">
                <button className="switchIcon">
                    <i className="fa-solid fa-palette" />
                </button>
                <h4 className="color-scheme-wrap-title">
                    <i className="far fa-palette" />
                    STYLE SWITCHER
                </h4>
                <div className="color-switch-btns">
                    <button data-color="#684DF4">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#086ad8">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#FC3737">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#8a2be2">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#104CBA">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#ffbf4f">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#323F7C">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#0e2bc5">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#F79C53">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#6957af">
                        <i className="fa-solid fa-droplet" />
                    </button>
                </div>
                <a
                    // href="https://themeforest.net/search/themeholy"
                    // className="th-btn text-center"
                >
                    <i className="fa fa-shopping-cart me-2" /> Purchase
                </a>
            </div>
          {/*  <div id="preloader" className="preloader">*/}
          {/*      <button className="th-btn preloaderCls">Cancel Preloader</button>*/}
          {/*      <div className="preloader-inner">*/}
          {/*          <img src="/assets/img/Gold_and_Black_Plane_Travel_Creative_Logo-removebg-preview.png" alt="" />*/}
          {/*      </div>*/}
          {/*      <div id="loader" className="th-preloader">*/}
          {/*          <div className="animation-preloader">*/}
          {/*              <div className="txt-loading">*/}
          {/*<span data-preloader-text="T" className="characters">*/}
          {/*  F{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="O" className="characters">*/}
          {/*  A{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="U" className="characters">*/}
          {/*  R{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="R" className="characters">*/}
          {/*  E{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  F{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  U{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  E{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  L{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  S{" "}*/}
          {/*</span>*/}


          {/*              </div>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*  </div>*/}
            <div className="sidemenu-wrapper sidemenu-info">
                <div className="sidemenu-content">
                    <button className="closeButton sideMenuCls">
                        <i className="far fa-times" />
                    </button>
                    <div className="widget">
                        <div className="th-widget-about">
                            <div className="about-logo">
                                <a href="home-travel.html">
                                    <img src="/assets/img/logo2.svg" alt="Tourm" />
                                </a>
                            </div>
                            <p className="about-text">
                                Rapidiously myocardinate cross-platform intellectual capital model.
                                Appropriately create interactive infrastructures
                            </p>
                            <div className="th-social">
                                <a href="https://www.facebook.com/">
                                    <i className="fab fa-facebook-f" />
                                </a>{" "}
                                <a href="https://www.twitter.com/">
                                    <i className="fab fa-twitter" />
                                </a>{" "}
                                <a href="https://www.linkedin.com/">
                                    <i className="fab fa-linkedin-in" />
                                </a>{" "}
                                <a href="https://www.whatsapp.com/">
                                    <i className="fab fa-whatsapp" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <h3 className="widget_title">Recent Posts</h3>
                        <div className="recent-post-wrap">
                            <div className="recent-post">
                                <div className="media-img">
                                    <a href="blog-details.html">
                                        <img
                                            src="/assets/img/blog/recent-post-1-1.jpg"
                                            alt="Blog"
                                        />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <div className="recent-post-meta">
                                        <a href="blog.html">
                                            <i className="far fa-calendar" />
                                            24 Jun , 2024
                                        </a>
                                    </div>
                                    <h4 className="post-title">
                                        <a className="text-inherit" href="blog-details.html">
                                            Where Vision Meets Concrete Reality
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div className="recent-post">
                                <div className="media-img">
                                    <a href="blog-details.html">
                                        <img
                                            src="/assets/img/blog/recent-post-1-2.jpg"
                                            alt="Blog Image"
                                        />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <div className="recent-post-meta">
                                        <a href="blog.html">
                                            <i className="far fa-calendar" />
                                            22 Jun , 2024
                                        </a>
                                    </div>
                                    <h4 className="post-title">
                                        <a className="text-inherit" href="blog-details.html">
                                            Raising the Bar in Construction.
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <h3 className="widget_title">Get In Touch</h3>
                        <div className="th-widget-contact">
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/phone.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>
                                        <a href="tel:+01234567890" className="info-box_link">
                                            +01 234 567 890
                                        </a>
                                    </p>
                                    <p>
                                        <a href="tel:+09876543210" className="info-box_link">
                                            +09 876 543 210
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/envelope.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>
                                        <a href="mailto:mailinfo00@tourm.com" className="info-box_link">
                                            mailinfo00@tourm.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href="mailto:support24@tourm.com" className="info-box_link">
                                            support24@tourm.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/location-dot.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>789 Inner Lane, Holy park, California, USA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-search-box">
                <button className="searchClose">
                    <i className="fal fa-times" />
                </button>
                <form action="#">
                    <input type="text" placeholder="What are you looking for?" />{" "}
                    <button type="submit">
                        <i className="fal fa-search" />
                    </button>
                </form>
            </div>
           <Header/>

            <div className="th-hero-wrapper hero-1" id="hero">
                <div
                    className="swiper th-slider hero-slider-1"
                    id="heroSlide1"
                    data-slider-options='{"effect":"fade","menu": ["", "", ""],"heroSlide1": {"swiper-container": {"pagination": {"el": ".swiper-pagination", "clickable": true }}}}'
                >
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="hero-inner">
                                <div
                                    className="th-hero-bg"
                                    data-bg-src="assets/img/hero/hero_bg_1_1.jpg"
                                />
                                <div className="container">
                                    <div className="hero-style1">
                <span
                    className="sub-title style1"
                    data-ani="slideinup"
                    data-ani-delay="0.2s"
                >
                  Get unforgetable pleasure with us
                </span>
                                        <h1
                                            className="hero-title"
                                            data-ani="slideinup"
                                            data-ani-delay="0.4s"
                                        >
                                            Natural Wonder of the world
                                        </h1>
                                        <div
                                            className="btn-group"
                                            data-ani="slideinup"
                                            data-ani-delay="0.6s"
                                        >
                                            <a href="tour.html" className="th-btn th-icon">
                                                Explore Tours
                                            </a>{" "}
                                            <a href="service.html" className="th-btn style2 th-icon">
                                                Our Services
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="hero-inner">
                                <div
                                    className="th-hero-bg"
                                    data-bg-src="assets/img/hero/hero_bg_1_2.jpg"
                                />
                                <div className="container">
                                    <div className="hero-style1">
                <span
                    className="sub-title style1"
                    data-ani="slideinup"
                    data-ani-delay="0.2s"
                >
                  Get unforgetable pleasure with us
                </span>
                                        <h1
                                            className="hero-title"
                                            data-ani="slideinup"
                                            data-ani-delay="0.4s"
                                        >
                                            Let’s make your best trip with us
                                        </h1>
                                        <div
                                            className="btn-group"
                                            data-ani="slideinup"
                                            data-ani-delay="0.6s"
                                        >
                                            <a href="tour.html" className="th-btn th-icon">
                                                Explore Tours
                                            </a>{" "}
                                            <a href="service.html" className="th-btn style2 th-icon">
                                                Our Services
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="hero-inner">
                                <div
                                    className="th-hero-bg"
                                    data-bg-src="assets/img/hero/hero_bg_1_3.jpg"
                                />
                                <div className="container">
                                    <div className="hero-style1">
                <span
                    className="sub-title style1"
                    data-ani="slideinup"
                    data-ani-delay="0.2s"
                >
                  Get unforgetable pleasure with us
                </span>
                                        <h1
                                            className="hero-title"
                                            data-ani="slideinup"
                                            data-ani-delay="0.4s"
                                        >
                                            Explore beauty of the whole world
                                        </h1>
                                        <div
                                            className="btn-group"
                                            data-ani="slideinup"
                                            data-ani-delay="0.6s"
                                        >
                                            <a href="tour.html" className="th-btn th-icon">
                                                Explore Tours
                                            </a>{" "}
                                            <a href="service.html" className="th-btn style2 th-icon">
                                                Our Services
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="th-swiper-custom">
                        <button
                            data-slider-prev="#heroSlide1"
                            className="slider-arrow slider-prev"
                        >
                            <img src="/assets/img/icon/right-arrow.svg" alt="" />
                        </button>
                        <div className="slider-pagination" />
                        <button
                            data-slider-next="#heroSlide1"
                            className="slider-arrow slider-next"
                        >
                            <img src="/assets/img/icon/left-arrow.svg" alt="" />
                        </button>
                    </div>
                </div>
            </div>

             {/*Form Start*/}

            {/*<BookingForm />*/}
            {/*<HotelBookingForm/>*/}

            {location.pathname === '/hotels' ? <HotelBookingForm /> : <BookingForm />}

            {/*From End*/}

            <section
                className="category-area bg-top-center"
                data-bg-src="assets/img/bg/category_bg_1.png"
            >
                <div className="container th-container">
                    <div className="title-area text-center">
                        <span className="sub-title">Wornderful Place For You</span>
                        <h2 className="sec-title">Tour Categories</h2>
                    </div>
                    <div className="swiper categorySlider" id="categorySlide">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_1.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Cruises</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_2.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Hiking</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_3.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Airbirds</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_4.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Wildlife</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_5.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Walking</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_1.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Cruises</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_2.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Hiking</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_3.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Airbirds</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_4.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Wildlife</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_5.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Walking</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_1.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Cruises</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_2.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Hiking</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_3.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Airbirds</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_4.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Wildlife</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="category-card single">
                                    <div className="box-img global-img">
                                        <img src="/assets/img/category/category_1_5.jpg" alt="Image" />
                                    </div>
                                    <h3 className="box-title">
                                        <a href="destination.html">Walking</a>
                                    </h3>
                                    <a className="line-btn" href="destination.html">
                                        See more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination" />
                    </div>
                </div>
            </section>
            <div className="destination-area position-relative overflow-hidden">
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title">Top Destination</span>
                        <h2 className="sec-title">Popular Destination</h2>
                    </div>
                    <div
                        className="swiper th-slider destination-slider slider-drag-wrap"
                        id="aboutSlider1"
                        data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}},"effect":"coverflow","coverflowEffect":{"rotate":"0","stretch":"95","depth":"212","modifier":"1"},"centeredSlides":"true"}'
                    >
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_1.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Maldives</a>
                                                </h4>
                                                <span className="destination-subtitle">15 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_2.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Thailand</a>
                                                </h4>
                                                <span className="destination-subtitle">22 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_3.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Belgium</a>
                                                </h4>
                                                <span className="destination-subtitle">25 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_4.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Island</a>
                                                </h4>
                                                <span className="destination-subtitle">28 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_5.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Maldives</a>
                                                </h4>
                                                <span className="destination-subtitle">30 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_1.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Maldives</a>
                                                </h4>
                                                <span className="destination-subtitle">15 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_2.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Thailand</a>
                                                </h4>
                                                <span className="destination-subtitle">22 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_3.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Belgium</a>
                                                </h4>
                                                <span className="destination-subtitle">25 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_4.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Island</a>
                                                </h4>
                                                <span className="destination-subtitle">28 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="destination-box gsap-cursor">
                                    <div className="destination-img">
                                        <img
                                            src="/assets/img/destination/destination_1_5.jpg"
                                            alt="destination image"
                                        />
                                        <div className="destination-content">
                                            <div className="media-left">
                                                <h4 className="box-title">
                                                    <a href="destination-details.html">Maldives</a>
                                                </h4>
                                                <span className="destination-subtitle">30 Listing</span>
                                            </div>
                                            <div className="">
                                                <a
                                                    href="destination-details.html"
                                                    className="th-btn style2 th-icon"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="about-area position-relative overflow-hidden space"
                id="about-sec"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="img-box1">
                                <div className="img1">
                                    <img src="/assets/img/normal/about_1_1.jpg" alt="About" />
                                </div>
                                <div className="img2">
                                    <img src="/assets/img/normal/about_1_2.jpg" alt="About" />
                                </div>
                                <div className="img3">
                                    <img src="/assets/img/normal/about_1_3.jpg" alt="About" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="ps-xl-4 ms-xl-2">
                                <div className="title-area mb-20 pe-xl-5 me-xl-5">
                                    <span className="sub-title style1">Let’s Go Together</span>
                                    <h2 className="sec-title mb-20 pe-xl-5 me-xl-5 heading">
                                        Plan Your Trip With us
                                    </h2>
                                    <p className="sec-text mb-30">
                                        There are many variations of passages of available but the
                                        majority have suffered alteration in some form, by injected hum
                                        randomised words which don't look even slightly.
                                    </p>
                                </div>
                                <div className="about-item-wrap">
                                    <div className="about-item">
                                        <div className="about-item_img">
                                            <img src="/assets/img/icon/map3.svg" alt="" />
                                        </div>
                                        <div className="about-item_centent">
                                            <h5 className="box-title">Exclusive Trip</h5>
                                            <p className="about-item_text">
                                                There are many variations of passages of available but the
                                                majority.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="about-item">
                                        <div className="about-item_img">
                                            <img src="/assets/img/icon/guide.svg" alt="" />
                                        </div>
                                        <div className="about-item_centent">
                                            <h5 className="box-title">Professional Guide</h5>
                                            <p className="about-item_text">
                                                There are many variations of passages of available but the
                                                majority.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-35">
                                    <a href="about.html" className="th-btn style3 th-icon">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup shape1 d-none d-xl-block"
                    data-top="12%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_1.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup shape2 d-none d-xl-block"
                    data-top="20%"
                    data-left="3%"
                >
                    <img src="/assets/img/shape/shape_2.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup shape3 d-none d-xl-block"
                    data-top="14%"
                    data-left="8%"
                >
                    <img src="/assets/img/shape/shape_3.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup about-shape movingX d-none d-xxl-block"
                    data-bottom="0%"
                    data-right="8%"
                >
                    <img src="/assets/img/normal/about-slide-img.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup about-rating d-none d-xxl-block"
                    data-bottom="45%"
                    data-right="2%"
                >
                    <i className="fa-sharp fa-solid fa-star" />
                    <span>4.9k</span>
                </div>
                <div
                    className="shape-mockup about-emoji d-none d-xxl-block"
                    data-bottom="25%"
                    data-right="22%"
                >
                    <img src="/assets/img/icon/emoji.png" alt="" />
                </div>
            </div>
            <section
                className="position-relative bg-top-center overflow-hidden space"
                id="service-sec"
                data-bg-src="assets/img/bg/tour_bg_1.jpg"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="title-area text-center">
                                <span className="sub-title">Best Place For You</span>
                                <h2 className="sec-title">Most Popular Tour</h2>
                                <p className="sec-text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slider-area tour-slider">
                        <div
                            className="swiper th-slider has-shadow slider-drag-wrap"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"},"1300":{"slidesPerView":"4"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="tour-box th-ani gsap-cursor">
                                        <div className="tour-box_img global-img">
                                            <img src="/assets/img/tour/tour_box_1.jpg" alt="image" />
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="box-title">
                                                <a href="tour-details.html">Greece Tour Package</a>
                                            </h3>
                                            <div className="tour-rating">
                                                <div
                                                    className="star-rating"
                                                    role="img"
                                                    aria-label="Rated 5.00 out of 5"
                                                >
                      <span style={{ width: "100%" }}>
                        Rated <strong className="rating">5.00</strong> out of 5
                        based on <span className="rating">4.8</span>(4.8 Rating)
                      </span>
                                                </div>
                                                <a
                                                    href="tour-details.html"
                                                    className="woocommerce-review-link"
                                                >
                                                    (<span className="count">4.8</span> Rating)
                                                </a>
                                            </div>
                                            <h4 className="tour-box_price">
                                                <span className="currency">₹980.00</span>/Person
                                            </h4>
                                            <div className="tour-action">
                    <span>
                      <i className="fa-light fa-clock" />7 Days
                    </span>{" "}
                                                <a href="contact.html" className="th-btn style4 th-icon">
                                                    Book Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="tour-box th-ani gsap-cursor">
                                        <div className="tour-box_img global-img">
                                            <img src="/assets/img/tour/tour_box_2.jpg" alt="image" />
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="box-title">
                                                <a href="tour-details.html">Italy Tour package</a>
                                            </h3>
                                            <div className="tour-rating">
                                                <div
                                                    className="star-rating"
                                                    role="img"
                                                    aria-label="Rated 5.00 out of 5"
                                                >
                      <span style={{ width: "100%" }}>
                        Rated <strong className="rating">5.00</strong> out of 5
                        based on <span className="rating">4.8</span>(4.8 Rating)
                      </span>
                                                </div>
                                                <a
                                                    href="tour-details.html"
                                                    className="woocommerce-review-link"
                                                >
                                                    (<span className="count">4.8</span> Rating)
                                                </a>
                                            </div>
                                            <h4 className="tour-box_price">
                                                <span className="currency">₹14999.00</span>/Person
                                            </h4>
                                            <div className="tour-action">
                    <span>
                      <i className="fa-light fa-clock" />7 Days
                    </span>{" "}
                                                <Link to="/contact" className="th-btn style4 th-icon">
                                                    Book Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="tour-box th-ani gsap-cursor">
                                        <div className="tour-box_img global-img">
                                            <img src="/assets/img/tour/tour_box_3.jpg" alt="image" />
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="box-title">
                                                <a href="tour-details.html">Dubai Tour Package</a>
                                            </h3>
                                            <div className="tour-rating">
                                                <div
                                                    className="star-rating"
                                                    role="img"
                                                    aria-label="Rated 5.00 out of 5"
                                                >
                      <span style={{ width: "100%" }}>
                        Rated <strong className="rating">5.00</strong> out of 5
                        based on <span className="rating">4.8</span>(4.8 Rating)
                      </span>
                                                </div>
                                                <a
                                                    href="tour-details.html"
                                                    className="woocommerce-review-link"
                                                >
                                                    (<span className="count">4.8</span> Rating)
                                                </a>
                                            </div>
                                            <h4 className="tour-box_price">
                                                <span className="currency">₹14999.00</span>/Person
                                            </h4>
                                            <div className="tour-action">
                    <span>
                      <i className="fa-light fa-clock" />7 Days
                    </span>{" "}
                                                <Link to="/contact" className="th-btn style4 th-icon">
                                                    Book Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="tour-box th-ani gsap-cursor">
                                        <div className="tour-box_img global-img">
                                            <img src="/assets/img/tour/tour_box_4.jpg" alt="image" />
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="box-title">
                                                <a href="tour-details.html">Switzerland</a>
                                            </h3>
                                            <div className="tour-rating">
                                                <div
                                                    className="star-rating"
                                                    role="img"
                                                    aria-label="Rated 5.00 out of 5"
                                                >
                      <span style={{ width: "100%" }}>
                        Rated <strong className="rating">5.00</strong> out of 5
                        based on <span className="rating">4.8</span>(4.8 Rating)
                      </span>
                                                </div>
                                                <a
                                                    href="tour-details.html"
                                                    className="woocommerce-review-link"
                                                >
                                                    (<span className="count">4.8</span> Rating)
                                                </a>
                                            </div>
                                            <h4 className="tour-box_price">
                                                <span className="currency">₹14999.00</span>/Person
                                            </h4>
                                            <div className="tour-action">
                    <span>
                      <i className="fa-light fa-clock" />7 Days
                    </span>{" "}
                                                <Link to="/contact" className="th-btn style4 th-icon">
                                                    Book Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="tour-box th-ani gsap-cursor">
                                        <div className="tour-box_img global-img">
                                            <img src="/assets/img/tour/tour_box_1.jpg" alt="image" />
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="box-title">
                                                <a href="tour-details.html">Greece Tour Package</a>
                                            </h3>
                                            <div className="tour-rating">
                                                <div
                                                    className="star-rating"
                                                    role="img"
                                                    aria-label="Rated 5.00 out of 5"
                                                >
                      <span style={{ width: "100%" }}>
                        Rated <strong className="rating">5.00</strong> out of 5
                        based on <span className="rating">4.8</span>(4.8 Rating)
                      </span>
                                                </div>
                                                <a
                                                    href="tour-details.html"
                                                    className="woocommerce-review-link"
                                                >
                                                    (<span className="count">4.8</span> Rating)
                                                </a>
                                            </div>
                                            <h4 className="tour-box_price">
                                                <span className="currency">₹14999.00</span>/Person
                                            </h4>
                                            <div className="tour-action">
                    <span>
                      <i className="fa-light fa-clock" />7 Days
                    </span>{" "}
                                                <Link to="/contact" className="th-btn style4 th-icon">
                                                    Book Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="tour-box th-ani gsap-cursor">
                                        <div className="tour-box_img global-img">
                                            <img src="/assets/img/tour/tour_box_2.jpg" alt="image" />
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="box-title">
                                                <a href="tour-details.html">Italy Tour package</a>
                                            </h3>
                                            <div className="tour-rating">
                                                <div
                                                    className="star-rating"
                                                    role="img"
                                                    aria-label="Rated 5.00 out of 5"
                                                >
                      <span style={{ width: "100%" }}>
                        Rated <strong className="rating">5.00</strong> out of 5
                        based on <span className="rating">4.8</span>(4.8 Rating)
                      </span>
                                                </div>
                                                <a
                                                    href="tour-details.html"
                                                    className="woocommerce-review-link"
                                                >
                                                    (<span className="count">4.8</span> Rating)
                                                </a>
                                            </div>
                                            <h4 className="tour-box_price">
                                                <span className="currency">₹14999.00</span>/Person
                                            </h4>
                                            <div className="tour-action">
                    <span>
                      <i className="fa-light fa-clock" />7 Days
                    </span>{" "}
                                                <Link to="/contact" className="th-btn style4 th-icon">
                                                    Book Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="gallery-area">
                <div className="container th-container">
                    <div className="title-area text-center">
                        <span className="sub-title">Make Your Tour More Pleasure</span>
                        <h2 className="sec-title">Recent Gallery</h2>
                    </div>
                    <div className="row gy-10 gx-10 justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-2">
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_1.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_1.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_2.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_2.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_3.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_3.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_4.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_4.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_5.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_5.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_6.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_6.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <div className="gallery-card">
                                <div className="box-img global-img">
                                    <a
                                        href="/assets/img/gallery/gallery_1_7.jpg"
                                        className="popup-image"
                                    >
                                        <div className="icon-btn">
                                            <i className="fal fa-magnifying-glass-plus" />
                                        </div>
                                        <img
                                            src="/assets/img/gallery/gallery_1_7.jpg"
                                            alt="gallery image"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup d-none d-xl-block"
                    data-top="-25%"
                    data-left="0%"
                >
                    <img src="/assets/img/shape/line.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup movingX d-none d-xl-block"
                    data-top="30%"
                    data-left="3%"
                >
                    <img
                        className="gmovingX"
                        src="/assets/img/shape/shape_4.png"
                        alt="shape"
                    />
                </div>
            </div>
            <div className="counter-area space">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-xl-3 counter-card-wrap">
                            <div className="counter-card">
                                <div className="counter-shape">
                                    <span />
                                </div>
                                <div className="media-body">
                                    <h3 className="box-number">
                                        <span className="counter-number">12</span>
                                    </h3>
                                    <h6 className="counter-title">Years Experience</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3 counter-card-wrap">
                            <div className="counter-card">
                                <div className="counter-shape">
                                    <span />
                                </div>
                                <div className="media-body">
                                    <h3 className="box-number">
                                        <span className="counter-number">97</span>%
                                    </h3>
                                    <h6 className="counter-title">Retention Rate</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3 counter-card-wrap">
                            <div className="counter-card">
                                <div className="counter-shape">
                                    <span />
                                </div>
                                <div className="media-body">
                                    <h3 className="box-number">
                                        <span className="counter-number">8</span>k
                                    </h3>
                                    <h6 className="counter-title">Tour Completed</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3 counter-card-wrap">
                            <div className="counter-card">
                                <div className="counter-shape">
                                    <span />
                                </div>
                                <div className="media-body">
                                    <h3 className="box-number">
                                        <span className="counter-number">19</span>k
                                    </h3>
                                    <h6 className="counter-title">Happy Travellers</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup shape1 d-none d-xl-block"
                    data-top="30%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_1.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup shape2 d-none d-xl-block"
                    data-top="45%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_2.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup shape3 d-none d-xl-block"
                    data-top="32%"
                    data-left="7%"
                >
                    <img src="/assets/img/shape/shape_3.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup d-none d-xl-block"
                    data-bottom="0%"
                    data-left="3%"
                >
                    <img src="/assets/img/shape/shape_6.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup jump d-none d-xl-block"
                    data-top="5%"
                    data-right="5%"
                >
                    <img src="/assets/img/shape/shape_5.png" alt="shape" />
                </div>
            </div>
            <section className="bg-smoke space" data-bg-src="assets/img/bg/team_bg_1.png">
                <div className="container z-index-common">
                    <div className="title-area text-center">
                        <span className="sub-title">Meet with Guide</span>
                        <h2 className="sec-title">Tour Guide</h2>
                    </div>
                    <div className="slider-area">
                        <div
                            className="swiper th-slider teamSlider1 has-shadow"
                            id="teamSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_1.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jacob Jones</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_2.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jane Cooper</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_3.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Guy Hawkins</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_4.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jenny Wilson</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_1.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jacob Jones</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_2.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jane Cooper</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_3.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Guy Hawkins</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-box">
                                        <div className="team-img">
                                            <img src="/assets/img/team/team_1_4.jpg" alt="Team" />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jenny Wilson</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-pagination" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="testi-area overflow-hidden space" id="testi-sec">
                <div className="container-fluid p-0">
                    <div className="title-area mb-20 text-center">
                        <span className="sub-title">Testimonial</span>
                        <h2 className="sec-title">What Client Say About us</h2>
                    </div>
                    <div className="slider-area">
                        <div
                            className="swiper th-slider testiSlider1 has-shadow"
                            id="testiSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"767":{"slidesPerView":"2","centeredSlides":"true"},"992":{"slidesPerView":"2","centeredSlides":"true"},"1200":{"slidesPerView":"2","centeredSlides":"true"},"1400":{"slidesPerView":"3","centeredSlides":"true"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Maria Doe</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “A home that perfectly blends sustainability with luxury until
                                            I discovered Ecoland Residence. From the moment I stepped into
                                            this community, I knew it was where I wanted to live. The
                                            commitment to eco-friendly living”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_2.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Andrew Simon</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “The home boasts sleek, contemporary architecture with clean
                                            lines and expansive windows, allowing natural light to flood
                                            the interiors It incorporates passive design principles”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Alex Jordan</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “Solar panels adorn the roof, harnessing renewable energy to
                                            power the home and even feed excess electricity back into the
                                            grid. High-performance insulation and triple-glazed”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_2.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Maria Doe</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            A sophisticated rainwater harvesting system collects and
                                            filters rainwater for irrigation and non-potable uses,
                                            reducing reliance on municipal water sources. Greywater
                                            systems
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Angelina Rose</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            Throughout the interior, eco-friendly materials like reclaimed
                                            wood, bamboo flooring, and recycled glass countertops create a
                                            luxurious yet sustainable ambiance.
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Maria Doe</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “A home that perfectly blends sustainability with luxury until
                                            I discovered Ecoland Residence. From the moment I stepped into
                                            this community, I knew it was where I wanted to live. The
                                            commitment to eco-friendly living”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_2.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Andrew Simon</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            A sophisticated rainwater harvesting system collects and
                                            filters rainwater for irrigation and non-potable uses,
                                            reducing reliance on municipal water sources. Greywater
                                            systems
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Alex Jordan</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            Throughout the interior, eco-friendly materials like reclaimed
                                            wood, bamboo flooring, and recycled glass countertops create a
                                            luxurious yet sustainable ambiance.
                                        </p>
                                        <div className="testi-card-quote">
                                            <img src="/assets/img/icon/testi-quote.svg" alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-pagination" />
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup d-none d-xl-block"
                    data-bottom="-2%"
                    data-right="0%"
                >
                    <img src="/assets/img/shape/line2.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup movingX d-none d-xl-block"
                    data-top="30%"
                    data-left="5%"
                >
                    <img src="/assets/img/shape/shape_7.png" alt="shape" />
                </div>
            </section>
            <div className="brand-area overflow-hidden space-bottom">
                <div className="container th-container">
                    <div
                        className="swiper th-slider brandSlider1"
                        id="brandSlider1"
                        data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"6"},"1400":{"slidesPerView":"8"}}}'
                    >
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_5.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_5.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_6.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_6.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_7.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_7.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_8.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_8.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-smoke overflow-hidden space" id="blog-sec">
                <div className="container">
                    <div className="mb-30 text-center text-md-start">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-7">
                                <div className="title-area mb-md-0">
                                    <span className="sub-title">About Us Restaurant</span>
                                    <h2 className="sec-title">News &amp; Articles From Tourm</h2>
                                </div>
                            </div>
                            <div className="col-md-auto">
                                <a href="blog.html" className="th-btn style4 th-icon">
                                    See More Articles
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="slider-area">
                        <div
                            className="swiper th-slider has-shadow"
                            id="blogSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="blog-box th-ani">
                                        <div className="blog-img global-img">
                                            <img src="/assets/img/blog/blog_1_1.jpg" alt="blog image" />
                                        </div>
                                        <div className="blog-box_content">
                                            <div className="blog-meta">
                                                <a className="author" href="blog.html">
                                                    July 05 2024
                                                </a>{" "}
                                                <a href="blog.html">6 min read</a>
                                            </div>
                                            <h3 className="box-title">
                                                <a href="blog-details.html">
                                                    10 Reason why you should visit New Jersy
                                                </a>
                                            </h3>
                                            <a href="blog-details.html" className="th-btn style4 th-icon">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="blog-box th-ani">
                                        <div className="blog-img global-img">
                                            <img src="/assets/img/blog/blog_1_2.jpg" alt="blog image" />
                                        </div>
                                        <div className="blog-box_content">
                                            <div className="blog-meta">
                                                <a className="author" href="blog.html">
                                                    July 06 2024
                                                </a>{" "}
                                                <a href="blog.html">7 min read</a>
                                            </div>
                                            <h3 className="box-title">
                                                <a href="blog-details.html">
                                                    The best time to visit japan &amp; enjoy the cherry
                                                    blossoms
                                                </a>
                                            </h3>
                                            <a href="blog-details.html" className="th-btn style4 th-icon">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="blog-box th-ani">
                                        <div className="blog-img global-img">
                                            <img src="/assets/img/blog/blog_1_3.jpg" alt="blog image" />
                                        </div>
                                        <div className="blog-box_content">
                                            <div className="blog-meta">
                                                <a className="author" href="blog.html">
                                                    July 07 2024
                                                </a>{" "}
                                                <a href="blog.html">8 min read</a>
                                            </div>
                                            <h3 className="box-title">
                                                <a href="blog-details.html">
                                                    The 7 amazing destinations for adventure seekers
                                                </a>
                                            </h3>
                                            <a href="blog-details.html" className="th-btn style4 th-icon">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="blog-box th-ani">
                                        <div className="blog-img global-img">
                                            <img src="/assets/img/blog/blog_1_1.jpg" alt="blog image" />
                                        </div>
                                        <div className="blog-box_content">
                                            <div className="blog-meta">
                                                <a className="author" href="blog.html">
                                                    July 09 2024
                                                </a>{" "}
                                                <a href="blog.html">9 min read</a>
                                            </div>
                                            <h3 className="box-title">
                                                <a href="blog-details.html">
                                                    10 Reason why you should visit New Jersy
                                                </a>
                                            </h3>
                                            <a href="blog-details.html" className="th-btn style4 th-icon">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="blog-box th-ani">
                                        <div className="blog-img global-img">
                                            <img src="/assets/img/blog/blog_1_2.jpg" alt="blog image" />
                                        </div>
                                        <div className="blog-box_content">
                                            <div className="blog-meta">
                                                <a className="author" href="blog.html">
                                                    July 10 2024
                                                </a>{" "}
                                                <a href="blog.html">10 min read</a>
                                            </div>
                                            <h3 className="box-title">
                                                <a href="blog-details.html">
                                                    The best time to visit japan &amp; enjoy the cherry
                                                    blossoms
                                                </a>
                                            </h3>
                                            <a href="blog-details.html" className="th-btn style4 th-icon">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="blog-box th-ani">
                                        <div className="blog-img global-img">
                                            <img src="/assets/img/blog/blog_1_3.jpg" alt="blog image" />
                                        </div>
                                        <div className="blog-box_content">
                                            <div className="blog-meta">
                                                <a className="author" href="blog.html">
                                                    July 12 2024
                                                </a>{" "}
                                                <a href="blog.html">11 min read</a>
                                            </div>
                                            <h3 className="box-title">
                                                <a href="blog-details.html">
                                                    The 7 amazing destinations for adventure seekers
                                                </a>
                                            </h3>
                                            <a href="blog-details.html" className="th-btn style4 th-icon">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup shape1 d-none d-xxl-block"
                    data-bottom="20%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_1.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup shape2 d-none d-xl-block"
                    data-bottom="5%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_2.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup shape3 d-none d-xxl-block"
                    data-bottom="12%"
                    data-left="7%"
                >
                    <img src="/assets/img/shape/shape_3.png" alt="shape" />
                </div>
            </section>
            {/*<footer className="footer-wrapper footer-layout1">*/}
            {/*    <div className="widget-area">*/}
            {/*        <div className="container">*/}
            {/*            <div className="newsletter-area">*/}
            {/*                <div className="newsletter-top">*/}
            {/*                    <div className="row gy-4 align-items-center">*/}
            {/*                        <div className="col-lg-5">*/}
            {/*                            <h2 className="newsletter-title text-capitalize mb-0">*/}
            {/*                                get updated the latest newsletter*/}
            {/*                            </h2>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-lg-7">*/}
            {/*                            <form className="newsletter-form">*/}
            {/*                                <input*/}
            {/*                                    className="form-control"*/}
            {/*                                    type="email"*/}
            {/*                                    placeholder="Enter Email"*/}
            {/*                                    required=""*/}
            {/*                                />{" "}*/}
            {/*                                <button type="submit" className="th-btn style3">*/}
            {/*                                    Subscribe Now <img src="/assets/img/icon/plane.svg" alt="" />*/}
            {/*                                </button>*/}
            {/*                            </form>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="row justify-content-between">*/}
            {/*                <div className="col-md-6 col-xl-3">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <div className="th-widget-about">*/}
            {/*                            <div className="about-logo">*/}
            {/*                                <a href="home-travel.html">*/}
            {/*                                    <img src="/assets/img/logo3.svg" alt="Tourm" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <p className="about-text">*/}
            {/*                                Rapidiously myocardinate cross-platform intellectual capital*/}
            {/*                                model. Appropriately create interactive infrastructures*/}
            {/*                            </p>*/}
            {/*                            <div className="th-social">*/}
            {/*                                <a href="https://www.facebook.com/">*/}
            {/*                                    <i className="fab fa-facebook-f" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.twitter.com/">*/}
            {/*                                    <i className="fab fa-twitter" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.linkedin.com/">*/}
            {/*                                    <i className="fab fa-linkedin-in" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.whatsapp.com/">*/}
            {/*                                    <i className="fab fa-whatsapp" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://instagram.com/">*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget widget_nav_menu footer-widget">*/}
            {/*                        <h3 className="widget_title">Quick Links</h3>*/}
            {/*                        <div className="menu-all-pages-container">*/}
            {/*                            <ul className="menu">*/}
            {/*                                <li>*/}
            {/*                                    <a href="   index.html">Home</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <li><Link to="/About">About Us</Link></li>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="service.html">Our Service</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="contact.html">Terms of Service</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="contact.html">Tour Booking Now</a>*/}
            {/*                                </li>*/}
            {/*                            </ul>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <h3 className="widget_title">Address</h3>*/}
            {/*                        <div className="th-widget-contact">*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img src="/assets/img/icon/phone.svg" alt="img" />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>*/}
            {/*                                        <a href="tel:+01234567890" className="info-box_link">*/}
            {/*                                            +01 234 567 890*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                    <p>*/}
            {/*                                        <a href="tel:+09876543210" className="info-box_link">*/}
            {/*                                            +09 876 543 210*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img src="/assets/img/icon/envelope.svg" alt="img" />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>*/}
            {/*                                        <a*/}
            {/*                                            href="mailto:mailinfo00@tourm.com"*/}
            {/*                                            className="info-box_link"*/}
            {/*                                        >*/}
            {/*                                            mailinfo00@tourm.com*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                    <p>*/}
            {/*                                        <a*/}
            {/*                                            href="mailto:support24@tourm.com"*/}
            {/*                                            className="info-box_link"*/}
            {/*                                        >*/}
            {/*                                            support24@tourm.com*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img src="/assets/img/icon/location-dot.svg" alt="img" />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>789 Inner Lane, Holy park, California, USA</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <h3 className="widget_title">Instagram Post</h3>*/}
            {/*                        <div className="sidebar-gallery">*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_1.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_2.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_3.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_4.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_5.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_6.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        className="copyright-wrap"*/}
            {/*        data-bg-src="assets/img/bg/copyright_bg_1.jpg"*/}
            {/*    >*/}
            {/*        <div className="container">*/}
            {/*            <div className="row justify-content-between align-items-center">*/}
            {/*                <div className="col-md-6">*/}
            {/*                    <p className="copyright-text">*/}
            {/*                        Copyright 2024 <a href="home-travel.html">Tourm</a>. All Rights*/}
            {/*                        Reserved.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 text-end d-none d-md-block">*/}
            {/*                    <div className="footer-card">*/}
            {/*                        <span className="title">We Accept</span>{" "}*/}
            {/*                        <img src="/assets/img/shape/cards.png" alt="" />*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</footer>*/}
            <Footer/>
            <div className="scroll-top">
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path
                        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        style={{
                            transition: "stroke-dashoffset 10ms linear 0s",
                            strokeDasharray: "307.919, 307.919",
                            strokeDashoffset: "307.919"
                        }}
                    />
                </svg>
            </div>
            <div id="login-form" className="popup-login-register mfp-hide">
                <ul className="nav" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                        >
                            Login
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu active"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="true"
                        >
                            Register
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <h3 className="box-title mb-30">Sign in to your account</h3>
                        <div className="th-login-form">
                            <form
                                action="https://html.themeholy.com/tourm/demo/mail.php"
                                method="POST"
                                className="login-form ajax-contact"
                            >
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>Username or email</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label>Password</label>{" "}
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="pasword"
                                            id="pasword"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-btn mb-20 col-12">
                                        <button className="th-btn btn-fw th-radius2">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                                <div id="forgot_url">
                                    <a href="my-account.html">Forgot password?</a>
                                </div>
                                <p className="form-messages mb-0 mt-3" />
                            </form>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade active show"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <h3 className="th-form-title mb-30">Sign in to your account</h3>
                        <form
                            action="https://html.themeholy.com/tourm/demo/mail.php"
                            method="POST"
                            className="login-form ajax-contact"
                        >
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Username*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="usename"
                                        id="usename"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>First name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="firstname"
                                        id="firstname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>Last name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lastname"
                                        id="lastname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email">Your email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email"
                                        id="new_email"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email_confirm">Confirm email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email_confirm"
                                        id="new_email_confirm"
                                        required="required"
                                    />
                                </div>
                                <div className="statement">
              <span className="register-notes">
                A password will be emailed to you.
              </span>
                                </div>
                                <div className="form-btn mt-20 col-12">
                                    <button className="th-btn btn-fw th-radius2">Sign up</button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3" />
                        </form>
                    </div>
                </div>
            </div>
            {/* Mirrored from html.themeholy.com/tourm/demo/index.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:14:22 GMT */}
        </div>

    )
}

export default Home;