import { useEffect } from 'react';

const useExternalScripts = () => {
    useEffect(() => {
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        };

        const scripts = [
            '/assets/js/vendor/jquery-3.6.0.min.js',
            '/assets/js/swiper-bundle.min.js',
            '/assets/js/bootstrap.min.js',
            '/assets/js/jquery.magnific-popup.min.js',
            '/assets/js/jquery.counterup.min.js',
            '/assets/js/jquery-ui.min.js',
            '/assets/js/imagesloaded.pkgd.min.js',
            '/assets/js/isotope.pkgd.min.js',
            '/assets/js/gsap.min.js',
            '/assets/js/circle-progress.js',
            '/assets/js/matter.min.js',
            '/assets/js/matterjs-custom.js',
            '/assets/js/nice-select.min.js',
            '/assets/js/main.js',
            "https://salesiq.zohopublic.in/widget?wc=siqc79172d0149183b5e3b1265aa5ce7f103f9b5d9f2abaa80f64200c490afb0feb924c1b29c9c94e9a192a5756cbb24d1b"

        ];

        scripts.forEach((script) => loadScript(script));
        // Add Zoho initialization script
        const initZoho = document.createElement('script');
        initZoho.innerHTML = `
            window.$zoho = window.$zoho || {};
            $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
        `;
        document.body.appendChild(initZoho);

        return () => {
            // Cleanup: remove the initialization script
            document.body.removeChild(initZoho);
        };
    }, []);
};

export default useExternalScripts;
