import React, { useState, useEffect, useRef, useMemo } from "react";
import { VariableSizeList as List } from "react-window";
import debounce from 'lodash.debounce';
import "./customSelect.css";

const CustomSelect = ({ options, value, onChange, label }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null); // Ref for the search input

    // Debounced search handler
    const handleSearch = debounce((term) => {
        setSearchTerm(term);
    }, 300); // Delay before performing search

    // Filter options based on search term
    const filteredOptions = useMemo(() => {
        console.log("Filtering with term:", searchTerm); // Debug log
        return options.filter(option =>
            option.cityCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
            option.cityName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            option.airportName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, options]);

    // Handle selecting an option
    const handleSelect = (option) => {
        onChange(option.cityCode); // Pass the cityCode as value
        setSearchTerm(""); // Clear search term
        setIsOpen(false); // Close dropdown
    };

    // Handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Open dropdown and focus search input
    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
        if (!isOpen) {
            setTimeout(() => {
                if (searchInputRef.current) {
                    searchInputRef.current.focus(); // Focus the search input when dropdown opens
                }
            }, 0);
        }
    };

    return (
        <div className="custom-select" ref={dropdownRef}>
            <label>{label}</label>
            <div className="select-container" onClick={toggleDropdown}>
                <div className="select-input">{value || "Select..."}</div>
            </div>
            {isOpen && (
                <div className="dropdown">
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e.target.value)} // Debounced search
                        className="search-input"
                        ref={searchInputRef} // Attach the ref to the input
                    />
                    <div className="options">
                        {filteredOptions.length > 0 ? (
                            <List
                                height={Math.min(400, filteredOptions.length * 40)} // Dynamic height based on filtered options
                                itemCount={filteredOptions.length}
                                itemSize={() => 80} // Fixed item size
                                width="100%"
                            >
                                {({ index, style }) => (
                                    <div
                                        key={filteredOptions[index].id}
                                        className="option"
                                        style={style} // Virtualization styling
                                        onClick={() => handleSelect(filteredOptions[index])}
                                    >
                                        <strong>{filteredOptions[index].cityCode}</strong> || {filteredOptions[index].cityName} <br />
                                        {filteredOptions[index].airportName}
                                    </div>
                                )}
                            </List>
                        ) : (
                            <div className="no-options">No options found</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
