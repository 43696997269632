import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import './PaymentSuccess.css'; // Import the CSS file for styling

const PaymentSuccess = () => {
    const [orderStatus, setOrderStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [responseParams, setResponseParams] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(searchParams.entries());
        console.log('Payment gateway response:', params); // For debugging purposes

        setResponseParams(params);

        const paymentStatus = searchParams.get('order_status');
        console.log('Extracted Payment Status:', paymentStatus);

        // Only set the orderStatus if it's not already set to "Success"
        if (!orderStatus) {
            // Check if order_status is available and valid
            if (!paymentStatus) {
                setOrderStatus('fail');
                setError('Payment failed.');
            } else if (paymentStatus === 'Success') {
                setOrderStatus('Success');
                console.log("Order status set to Success");
            } else {
                setOrderStatus('fail');
                setError('Payment failed.');
                console.log("Order status set to fail");
            }
        }

        setLoading(false);
    }, [location.search, orderStatus]);

    useEffect(() => {
        // Remove the parameters from the URL **after** loading is complete and orderStatus is not "Success"
        if (!loading && orderStatus !== 'Success') {
            navigate(location.pathname, { replace: true });
        }
    }, [loading, orderStatus, location.pathname, navigate]);

    // Function to generate PDF
    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text('Payment Response Parameters', 10, 10);
        let y = 20; // Start position for the text

        Object.entries(responseParams).forEach(([key, value]) => {
            doc.text(`${key}: ${value}`, 10, y);
            y += 10; // Move down for the next line
        });

        doc.save('payment-response.pdf');
    };

    // Display loading state or final status based on conditions
    if (loading) {
        return <div className="loading">Processing your payment, please wait...</div>;
    }

    return (
        <div className="payment-success-container">
            {orderStatus === 'Success' ? (
                <div className="payment-status success">
                    <h2>Payment Successful!</h2>
                    <p>Your payment was processed successfully.</p>
                    <button onClick={generatePDF} className="download-pdf-button">Download Payment Response as PDF</button>
                    <h3>Response Parameters:</h3>
                    <table className="response-params-table">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(responseParams).map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="payment-status fail">
                    <h2>Payment Failed</h2>
                    <p>{error || 'There was an issue with your payment. Please try again.'}</p>
                    {Object.keys(responseParams).length > 0 && (
                        <div>
                            <h3>Response Parameters:</h3>
                            <table className="response-params-table">
                                <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.entries(responseParams).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PaymentSuccess;
