import React, {useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";

const Service = () => {

    useEffect(() => {
        // Dynamically load external scripts
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        };

        const scripts = [
            '/assets/js/vendor/jquery-3.6.0.min.js',
            '/assets/js/swiper-bundle.min.js',
            '/assets/js/bootstrap.min.js',
            '/assets/js/jquery.magnific-popup.min.js',
            '/assets/js/jquery.counterup.min.js',
            '/assets/js/jquery-ui.min.js',
            '/assets/js/imagesloaded.pkgd.min.js',
            '/assets/js/isotope.pkgd.min.js',
            '/assets/js/gsap.min.js',
            '/assets/js/circle-progress.js',
            '/assets/js/matter.min.js',
            '/assets/js/matterjs-custom.js',
            '/assets/js/nice-select.min.js',
            '/assets/js/main.js',
        ];

        // Load all scripts
        scripts.forEach((script) => loadScript(script));
    }, []);


    return (

        <>
            {/* Mirrored from html.themeholy.com/tourm/demo/service.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:13:57 GMT */}
            <meta charSet="utf-8"/>
            <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
            <title>
                Tourm - Travel &amp; Tour Booking Agency HTML Template - Services
            </title>
            <meta name="author" content="Tourm"/>
            <meta
                name="description"
                content="Tourm - Travel & Tour Booking Agency HTML Template "
            />
            <meta
                name="keywords"
                content="Tourm - Travel & Tour Booking Agency HTML Template "
            />
            <meta name="robots" content="INDEX,FOLLOW"/>
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
            />
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/assets/img/favicons/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/assets/img/favicons/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/assets/img/favicons/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/assets/img/favicons/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/assets/img/favicons/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/assets/img/favicons/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/assets/img/favicons/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/assets/img/favicons/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/assets/img/favicons/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/assets/img/favicons/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/assets/img/favicons/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/assets/img/favicons/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/assets/img/favicons/favicon-16x16.png"
            />
            <link rel="manifest" href="/assets/img/favicons/manifest.json"/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta
                name="msapplication-TileImage"
                content="assets/img/favicons/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff"/>
            <link rel="preconnect" href="https://fonts.googleapis.com/"/>
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin=""/>
            <link rel="preconnect" href="https://fonts.googleapis.com/"/>
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin=""/>
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montez&display=swap"
                rel="stylesheet"
            />
            <link rel="stylesheet" href="assets/css/bootstrap.min.css"/>
            <link rel="stylesheet" href="assets/css/fontawesome.min.css"/>
            <link rel="stylesheet" href="assets/css/magnific-popup.min.css"/>
            <link rel="stylesheet" href="assets/css/swiper-bundle.min.css"/>
            <link rel="stylesheet" href="assets/css/style.css"/>
            <div className="magic-cursor relative z-10">
                <div className="cursor"/>
                <div className="cursor-follower"/>
            </div>
            <div className="color-scheme-wrap active">
                <button className="switchIcon">
                    <i className="fa-solid fa-palette"/>
                </button>
                <h4 className="color-scheme-wrap-title">
                    <i className="far fa-palette"/>
                    STYLE SWITCHER
                </h4>
                <div className="color-switch-btns">
                    <button data-color="#684DF4">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#086ad8">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#FC3737">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#8a2be2">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#104CBA">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#ffbf4f">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#323F7C">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#0e2bc5">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#F79C53">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                    {" "}
                    <button data-color="#6957af">
                        <i className="fa-solid fa-droplet"/>
                    </button>
                </div>
                <a
                    href="https://themeforest.net/search/themeholy"
                    className="th-btn text-center"
                >
                    <i className="fa fa-shopping-cart me-2"/> Purchase
                </a>
            </div>
          {/*  <div id="preloader" className="preloader">*/}
          {/*      <button className="th-btn preloaderCls">Cancel Preloader</button>*/}
          {/*      <div className="preloader-inner">*/}
          {/*          <img src="/assets/img/Gold_and_Black_Plane_Travel_Creative_Logo-removebg-preview.png" alt=""/>*/}
          {/*      </div>*/}
          {/*      <div id="loader" className="th-preloader">*/}
          {/*          <div className="animation-preloader">*/}
          {/*              <div className="txt-loading">*/}
          {/*<span data-preloader-text="T" className="characters">*/}
          {/*  F{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="O" className="characters">*/}
          {/*  A{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="U" className="characters">*/}
          {/*  R{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="R" className="characters">*/}
          {/*  E{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  F{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  U{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  E{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  L{" "}*/}
          {/*</span>*/}
          {/*                  <span data-preloader-text="M" className="characters">*/}
          {/*  S{" "}*/}
          {/*</span>*/}


          {/*              </div>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*  </div>*/}

            <Header/>
            <div
                className="breadcumb-wrapper"
                data-bg-src="assets/img/bg/breadcumb-bg.jpg"
            >
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Services</h1>
                        <ul className="breadcumb-menu">
                            <li>
                                <a href="home-travel.html">Home</a>
                            </li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section
                className="position-relative overflow-hidden space"
                id="destination-sec"
            >
                <div className="container">
                    <div className="row gy-4 gx-4">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_1.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Photo Shoot</a>
                                    </h3>
                                    <p className="destination-text">20 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_2.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Tour Guide</a>
                                    </h3>
                                    <p className="destination-text">22 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_3.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Cozy Event</a>
                                    </h3>
                                    <p className="destination-text">23 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_4.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Interesting Rest</a>
                                    </h3>
                                    <p className="destination-text">24 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_5.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Kayaking</a>
                                    </h3>
                                    <p className="destination-text">25 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_6.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Safe Flight</a>
                                    </h3>
                                    <p className="destination-text">26 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_7.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Entertainment</a>
                                    </h3>
                                    <p className="destination-text">27 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_8.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Delicisious Food</a>
                                    </h3>
                                    <p className="destination-text">28 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup shape1 d-none d-xxl-block"
                    data-bottom="17%"
                    data-right="9%"
                >
                    <img src="/assets/img/shape/shape_1.png" alt="shape"/>
                </div>
                <div
                    className="shape-mockup shape2 d-none d-xl-block"
                    data-bottom="8%"
                    data-right="8%"
                >
                    <img src="/assets/img/shape/shape_2.png" alt="shape"/>
                </div>
                <div
                    className="shape-mockup shape3 d-none d-xxl-block"
                    data-bottom="15%"
                    data-right="4%"
                >
                    <img src="/assets/img/shape/shape_3.png" alt="shape"/>
                </div>
            </section>
            <section
                className="position-relative bg-top-center overflow-hidden space"
                id="service-sec"
                data-bg-src="assets/img/bg/tour_bg_1.jpg"
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="title-area text-center">
                                <span className="sub-title">Best Experience</span>
                                <h2 className="sec-title">Amazing Travel Experience</h2>
                            </div>
                        </div>
                    </div>
                    <div className="nav nav-tabs tour-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link th-btn active"
                            id="nav-step1-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-step1"
                            type="button"
                        >
                            <img src="/assets/img/icon/tour_icon_1.svg" alt=""/>
                            Tour Package
                        </button>
                        {" "}
                        <button
                            className="nav-link th-btn"
                            id="nav-step2-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-step2"
                            type="button"
                        >
                            <img src="/assets/img/icon/tour_icon_2.svg" alt=""/>
                            Hotel
                        </button>
                        {" "}
                        <button
                            className="nav-link th-btn"
                            id="nav-step3-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-step3"
                            type="button"
                        >
                            <img src="/assets/img/icon/tour_icon_3.svg" alt=""/>
                            Transport
                        </button>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade active show"
                            id="nav-step1"
                            role="tabpanel"
                        >
                            <div className="slider-area tour-slider slider-drag-wrap">
                                <div
                                    className="swiper th-slider has-shadow"
                                    data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"},"1400":{"slidesPerView":"4"}}}'
                                >
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_1.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Greece Tour Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_2.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Italy Tour package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_3.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Dubai Tour Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_4.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Switzerland</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_1.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Greece Tour Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_2.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Italy Tour package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_3.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Dubai Tour Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_4.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Switzerland</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="contact.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-pagination"/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-step2" role="tabpanel">
                            <div className="slider-area tour-slider slider-drag-wrap">
                                <div
                                    className="swiper th-slider has-shadow"
                                    data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"},"1400":{"slidesPerView":"4"}}}'
                                >
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_5.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">The Plaza, New York</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_6.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Hotel Ritz Paris</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$970.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_7.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Claridge’s, London</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$960.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_8.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Taj Mahal Palace, India</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$940.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_9.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Peninsula Hong Kong</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$970.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_10.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">The Ritz Hotel London</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$940.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_11.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">
                                                            The Shelbourne Hotel, Dublin
                                                        </a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$990.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_12.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Beverly Hills Hotel</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$950.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-pagination"/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-step3" role="tabpanel">
                            <div className="slider-area tour-slider slider-drag-wrap">
                                <div
                                    className="swiper th-slider has-shadow"
                                    data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"},"1400":{"slidesPerView":"4"}}}'
                                >
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_13.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Caravan Trip Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_14.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Sleeper Buses Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_15.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Trips by Train Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_16.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">
                                                            Travel by Air Flight Package
                                                        </a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_17.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">
                                                            Cruise Transports Package
                                                        </a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_18.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">
                                                            Travel by Air Flight Package
                                                        </a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_19.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Sleeper Buses Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tour-box th-ani gsap-cursor">
                                                <div className="tour-box_img global-img">
                                                    <img
                                                        src="/assets/img/tour/tour_box_20.jpg"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="box-title">
                                                        <a href="tour-details.html">Trips by Train Package</a>
                                                    </h3>
                                                    <div className="tour-rating">
                                                        <div
                                                            className="star-rating"
                                                            role="img"
                                                            aria-label="Rated 5.00 out of 5"
                                                        >
                          <span style={{width: "100%"}}>
                            Rated <strong className="rating">5.00</strong> out
                            of 5 based on <span className="rating">4.8</span>
                            (4.8 Rating)
                          </span>
                                                        </div>
                                                        <a
                                                            href="tour-details.html"
                                                            className="woocommerce-review-link"
                                                        >
                                                            (<span className="count">4.8</span> Rating)
                                                        </a>
                                                    </div>
                                                    <h4 className="tour-box_price">
                                                        <span className="currency">$980.00</span>/Person
                                                    </h4>
                                                    <div className="tour-action">
                        <span>
                          <i className="fa-light fa-clock"/>7 Days
                        </span>{" "}
                                                        <a
                                                            href="tour-guider-details.html"
                                                            className="th-btn style4 th-icon"
                                                        >
                                                            Book Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-pagination"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="position-relative space-bottom">
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title">Let’s Checkin</span>
                        <h2 className="sec-title">Affordable Pricing plans</h2>
                    </div>
                    <div className="row gy-4 justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="price-card">
                                <div className="offer-tag">Popular</div>
                                <div className="price-card_wrapp">
                                    <div className="price-card_icon">
                                        <img
                                            src="/assets/img/icon/price_1_1.svg"
                                            alt="img"
                                        />
                                    </div>
                                    <h3 className="box-title">Basic Plan</h3>
                                </div>
                                <div className="price-card_content">
                                    <h4 className="price-card_price">
                                        $785.00<span className="duration">/Per Night</span>
                                    </h4>
                                    <p className="price-card_text">
                                        Essential services for basic and signle person seeking.
                                    </p>
                                    <div className="checklist">
                                        <ul>
                                            <li>Up to 2 members</li>
                                            <li>Room Service Fees</li>
                                            <li>Driver Service Feet</li>
                                            <li>Tour management</li>
                                            <li>Collaboration</li>
                                            <li>Entrance Fees</li>
                                            <li>Guide Fees in Destination</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="price-btn">
                                    <a href="contact.html" className="th-btn style3 w-100">
                                        Get Started
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price-card active">
                                <div className="offer-tag">Popular</div>
                                <div className="price-card_wrapp">
                                    <div className="price-card_icon">
                                        <img
                                            src="/assets/img/icon/price_1_2.svg"
                                            alt="img"
                                        />
                                    </div>
                                    <h3 className="box-title">Advanced Plan</h3>
                                </div>
                                <div className="price-card_content">
                                    <h4 className="price-card_price">
                                        $980.00<span className="duration">/Per Night</span>
                                    </h4>
                                    <p className="price-card_text">
                                        Essential services for basic and signle person seeking.
                                    </p>
                                    <div className="checklist">
                                        <ul>
                                            <li>Up to 2 members</li>
                                            <li>Room Service Fees</li>
                                            <li>Driver Service Feet</li>
                                            <li>Tour management</li>
                                            <li>Collaboration</li>
                                            <li>Entrance Fees</li>
                                            <li>Auto refresh ads</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="price-btn">
                                    <a href="contact.html" className="th-btn style3 w-100">
                                        Get Started
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price-card">
                                <div className="offer-tag">Popular</div>
                                <div className="price-card_wrapp">
                                    <div className="price-card_icon">
                                        <img
                                            src="/assets/img/icon/price_1_3.svg"
                                            alt="img"
                                        />
                                    </div>
                                    <h3 className="box-title">Premium Plan</h3>
                                </div>
                                <div className="price-card_content">
                                    <h4 className="price-card_price">
                                        $1580.00<span className="duration">/Per Night</span>
                                    </h4>
                                    <p className="price-card_text">
                                        Essential services for basic and signle person seeking.
                                    </p>
                                    <div className="checklist">
                                        <ul>
                                            <li>Up to 2 members</li>
                                            <li>Room Service Fees</li>
                                            <li>Driver Service Feet</li>
                                            <li>Tour management</li>
                                            <li>Collaboration</li>
                                            <li>Entrance Fees</li>
                                            <li>Auto refresh ads</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="price-btn">
                                    <a href="contact.html" className="th-btn style3 w-100">
                                        Get Started
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup movingX d-none d-xxl-block"
                    data-bottom="0%"
                    data-right="3%"
                >
                    <img
                        className="gmovingX"
                        src="/assets/img/normal/about-slide-img.png"
                        alt="shape"
                    />
                </div>
                <div
                    className="shape-mockup d-none d-xl-block"
                    data-bottom="0%"
                    data-left="3%"
                >
                    <img src="/assets/img/shape/shape_6.png" alt="shape"/>
                </div>
                <div
                    className="shape-mockup jump d-none d-xl-block"
                    data-top="15%"
                    data-left="6%"
                >
                    <img src="/assets/img/shape/shape_2_2.png" alt="shape"/>
                </div>
            </section>
            {/*<footer className="footer-wrapper bg-title footer-layout2">*/}
            {/*    <div className="widget-area">*/}
            {/*        <div className="container">*/}
            {/*            <div className="newsletter-area">*/}
            {/*                <div className="newsletter-top">*/}
            {/*                    <div className="row gy-4 align-items-center">*/}
            {/*                        <div className="col-lg-5">*/}
            {/*                            <h2 className="newsletter-title text-white text-capitalize mb-0">*/}
            {/*                                get updated the latest newsletter*/}
            {/*                            </h2>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-lg-7">*/}
            {/*                            <form className="newsletter-form style2">*/}
            {/*                                <input*/}
            {/*                                    className="form-control"*/}
            {/*                                    type="email"*/}
            {/*                                    placeholder="Enter Email"*/}
            {/*                                    required=""*/}
            {/*                                />{" "}*/}
            {/*                                <button type="submit" className="th-btn style1">*/}
            {/*                                    Subscribe Now{" "}*/}
            {/*                                    <img src="/assets/img/icon/plane2.svg" alt="" />*/}
            {/*                                </button>*/}
            {/*                            </form>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="row justify-content-between">*/}
            {/*                <div className="col-md-6 col-xl-3">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <div className="th-widget-about">*/}
            {/*                            <div className="about-logo">*/}
            {/*                                <a href="home-travel.html">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/logo-white.svg"*/}
            {/*                                        alt="Tourm"*/}
            {/*                                    />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <p className="about-text">*/}
            {/*                                Rapidiously myocardinate cross-platform intellectual capital*/}
            {/*                                model. Appropriately create interactive infrastructures*/}
            {/*                            </p>*/}
            {/*                            <div className="th-social">*/}
            {/*                                <a href="https://www.facebook.com/">*/}
            {/*                                    <i className="fab fa-facebook-f" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.twitter.com/">*/}
            {/*                                    <i className="fab fa-twitter" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.linkedin.com/">*/}
            {/*                                    <i className="fab fa-linkedin-in" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.whatsapp.com/">*/}
            {/*                                    <i className="fab fa-whatsapp" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://instagram.com/">*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget widget_nav_menu footer-widget">*/}
            {/*                        <h3 className="widget_title">Quick Links</h3>*/}
            {/*                        <div className="menu-all-pages-container">*/}
            {/*                            <ul className="menu">*/}
            {/*                                <li>*/}
            {/*                                    <a href="index.html">Home</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="about.html">About us</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="service.html">Our Service</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="contact.html">Terms of Service</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="contact.html">Tour Booking Now</a>*/}
            {/*                                </li>*/}
            {/*                            </ul>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <h3 className="widget_title">Get In Touch</h3>*/}
            {/*                        <div className="th-widget-contact">*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/icon/phone.svg"*/}
            {/*                                        alt="img"*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>*/}
            {/*                                        <a href="tel:+01234567890" className="info-box_link">*/}
            {/*                                            +01 234 567 890*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                    <p>*/}
            {/*                                        <a href="tel:+09876543210" className="info-box_link">*/}
            {/*                                            +09 876 543 210*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/icon/envelope.svg"*/}
            {/*                                        alt="img"*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>*/}
            {/*                                        <a*/}
            {/*                                            href="mailto:mailinfo00@tourm.com"*/}
            {/*                                            className="info-box_link"*/}
            {/*                                        >*/}
            {/*                                            mailinfo00@tourm.com*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                    <p>*/}
            {/*                                        <a*/}
            {/*                                            href="mailto:support24@tourm.com"*/}
            {/*                                            className="info-box_link"*/}
            {/*                                        >*/}
            {/*                                            support24@tourm.com*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/icon/location-dot.svg"*/}
            {/*                                        alt="img"*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>789 Inner Lane, Holy park, California, USA</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <h3 className="widget_title">Instagram Post</h3>*/}
            {/*                        <div className="sidebar-gallery">*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_1.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_2.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_3.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_4.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_5.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_6.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="copyright-wrap">*/}
            {/*        <div className="container">*/}
            {/*            <div className="row justify-content-between align-items-center">*/}
            {/*                <div className="col-md-6">*/}
            {/*                    <p className="copyright-text">*/}
            {/*                        Copyright 2024 <a href="home-travel.html">Tourm</a>. All Rights*/}
            {/*                        Reserved.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 text-end d-none d-md-block">*/}
            {/*                    <div className="footer-card">*/}
            {/*                        <span className="title">We Accept</span>{" "}*/}
            {/*                        <img src="/assets/img/shape/cards.png" alt="" />*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        className="shape-mockup movingX d-none d-xxl-block"*/}
            {/*        data-top="24%"*/}
            {/*        data-left="5%"*/}
            {/*    >*/}
            {/*        <img src="/assets/img/shape/shape_8.png" alt="shape" />*/}
            {/*    </div>*/}
            {/*</footer>*/}
            <Footer/>
            <div className="scroll-top">
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path
                        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        style={{
                            transition: "stroke-dashoffset 10ms linear 0s",
                            strokeDasharray: "307.919, 307.919",
                            strokeDashoffset: "307.919"
                        }}
                    />
                </svg>
            </div>
            <div id="login-form" className="popup-login-register mfp-hide">
                <ul className="nav" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                        >
                            Login
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu active"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="true"
                        >
                            Register
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <h3 className="box-title mb-30">Sign in to your account</h3>
                        <div className="th-login-form">
                            <form
                                action="https://html.themeholy.com/tourm/demo/mail.php"
                                method="POST"
                                className="login-form ajax-contact"
                            >
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>Username or email</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label>Password</label>{" "}
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="pasword"
                                            id="pasword"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-btn mb-20 col-12">
                                        <button className="th-btn btn-fw th-radius2">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                                <div id="forgot_url">
                                    <a href="my-account.html">Forgot password?</a>
                                </div>
                                <p className="form-messages mb-0 mt-3"/>
                            </form>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade active show"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <h3 className="th-form-title mb-30">Sign in to your account</h3>
                        <form
                            action="https://html.themeholy.com/tourm/demo/mail.php"
                            method="POST"
                            className="login-form ajax-contact"
                        >
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Username*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="usename"
                                        id="usename"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>First name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="firstname"
                                        id="firstname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>Last name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lastname"
                                        id="lastname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email">Your email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email"
                                        id="new_email"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email_confirm">Confirm email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email_confirm"
                                        id="new_email_confirm"
                                        required="required"
                                    />
                                </div>
                                <div className="statement">
              <span className="register-notes">
                A password will be emailed to you.
              </span>
                                </div>
                                <div className="form-btn mt-20 col-12">
                                    <button className="th-btn btn-fw th-radius2">Sign up</button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"/>
                        </form>
                    </div>
                </div>
            </div>
            {/* Mirrored from html.themeholy.com/tourm/demo/service.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:13:59 GMT */}
        </>


    )
}

export default Service;