import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUpMode, setIsSignUpMode] = useState(false);

    const navigate = useNavigate();

    const login = () => {
        const request = { email, password };

        // Uncomment and integrate LoginService when ready
        // LoginService.login(request)
        //     .then(response => {
        //         navigate('/home'); // Navigate to home page on successful login
        //     })
        //     .catch(error => {
        //         console.error('Login Error:', error);
        //     });
    };

    useEffect(() => {
        const handleSignUpClick = () => setIsSignUpMode(true);
        const handleSignInClick = () => setIsSignUpMode(false);

        const signUpBtn = document.querySelector('#sign-up-btn');
        const signInBtn = document.querySelector('#sign-in-btn');

        signUpBtn?.addEventListener('click', handleSignUpClick);
        signInBtn?.addEventListener('click', handleSignInClick);

        return () => {
            signUpBtn?.removeEventListener('click', handleSignUpClick);
            signInBtn?.removeEventListener('click', handleSignInClick);
        };
    }, []);

    return (
        <div className="login-page">
            <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
                <div className="forms-container">
                    <div className="signin-signup">
                        {/* Sign In Form */}
                        {!isSignUpMode && (
                            <form className="sign-in-form" onSubmit={(e) => e.preventDefault()}>
                                <h2 className="title">Sign in</h2>
                                <div className="input-field">
                                    <i className="fas fa-user"></i>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="input-field">
                                    <i className="fas fa-lock"></i>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <button className="btn solid" onClick={login}>
                                    Login
                                </button>
                            </form>
                        )}

                        {/* Sign Up Form */}
                        {isSignUpMode && (
                            <form className="sign-up-form" onSubmit={(e) => e.preventDefault()}>
                                <h2 className="title">Sign up</h2>
                                <div className="input-field">
                                    <i className="fas fa-user"></i>
                                    <input type="text" placeholder="Username" />
                                </div>
                                <div className="input-field">
                                    <i className="fas fa-envelope"></i>
                                    <input type="email" placeholder="Email" />
                                </div>
                                <div className="input-field">
                                    <i className="fas fa-lock"></i>
                                    <input type="password" placeholder="Password" />
                                </div>
                                <input type="submit" className="btn" value="Sign up" />
                            </form>
                        )}
                    </div>
                </div>

                <div className="panels-container">
                    <div className="panel left-panel">
                        <div className="content">
                            <h3>New here?</h3>
                            <p>Securely Enter Your Credentials - Access Your Customized User Experience.</p>
                            <button className="btn transparent" id="sign-up-btn">
                                Sign up
                            </button>
                        </div>
                        <img
                            src="/assets/img/sign-page-abstract-concept-illustration.png"
                            className="image"
                            alt=""
                        />
                    </div>
                    <div className="panel right-panel">
                        <div className="content">
                            <h3>One of us?</h3>
                            <p>Start Your Journey Today - Register to Unlock Exclusive Features, Offers, and Personalized Recommendations for Seamless Integration.</p>
                            <button className="btn transparent" id="sign-in-btn">
                                Sign in
                            </button>
                        </div>
                        <img
                            src="/assets/img/data-stealing-malware-abstract-concept-illustration.png"
                            className="image"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
