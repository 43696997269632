// Refactored authenticateUser function calling the auth method
import {auth} from "../BookingForm/Service/BookingService";

export async function authenticateUser() {
    try {
        // Call the auth function which internally uses sendRequest
        const data = await auth();

        console.log('API Response:', data);

        const tokenId = data.tokenId;
        if (tokenId) {
            // Store token in localStorage
            localStorage.setItem('tokenId', tokenId);
            console.log('Token saved to localStorage:', tokenId);
        } else {
            console.warn('TokenId not found in response');
        }
    } catch (error) {
        console.error('Error during authentication:', error);
    }
}
