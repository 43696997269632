// Loader.js
import React from 'react';
import './Loader.css'; // Assuming you'll create a CSS file for styling

const Loader = () => {
    return (
        <div className="loader">
            <div className="spinner"></div>
            <span>Searching Flights For You...</span>
        </div>
    );
};

export default Loader;
