import React, { useState, useEffect, useRef, useMemo } from "react";
import { VariableSizeList as List } from "react-window";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import "./HotelBookingForm.css";

const CustomDropdown = ({ options, value, onChange, label, isLoading, loadMoreData, searchKey }) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null); // For focusing the input when dropdown opens

    // Debounced search handler
    const handleSearch = debounce((term) => {
        setQuery(term);
    }, 300);

    // Filter options based on search term
    const filteredOptions = useMemo(() => {
        return options.filter((option) =>
            option[searchKey]?.toLowerCase().includes(query.toLowerCase())
        );
    }, [query, options, searchKey]);

    const handleSelect = (selectedValue) => {
        onChange(selectedValue); // Pass the selected value
        setQuery(selectedValue); // Set input to selected value
        setIsOpen(false); // Close dropdown after selection
    };

    // Handle click outside of dropdown to close it
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Handle toggle of dropdown visibility
    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
        if (!isOpen) {
            setTimeout(() => {
                if (searchInputRef.current) {
                    searchInputRef.current.focus(); // Focus on the input when dropdown opens
                }
            }, 0);
        }
    };

    return (
        <div className="custom-select" ref={dropdownRef}>
            <label>{label}</label>
            <div className="select-container" onClick={toggleDropdown}>
                <div className="select-input">{value || "Select..."}</div>
            </div>
            {isOpen && (
                <div className="dropdown">
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e.target.value)} // Debounced search
                        className="search-input"
                        ref={searchInputRef} // Attach ref to the search input
                    />
                    <div className="options">
                        {isLoading && <div className="loading">Loading...</div>}
                        {filteredOptions.length > 0 ? (
                            <List
                                height={Math.min(400, filteredOptions.length * 40)} // Dynamic height based on number of items
                                itemCount={filteredOptions.length}
                                itemSize={() => 80} // Fixed item size
                                width="100%"
                            >
                                {({ index, style }) => (
                                    <div
                                        key={filteredOptions[index].id || index} // Use a unique key
                                        className="option"
                                        style={style} // Virtualized style
                                        onClick={() => handleSelect(filteredOptions[index][searchKey])}
                                    >
                                        <strong>{filteredOptions[index].cityCode}</strong> ||{" "}
                                        {filteredOptions[index].cityName} <br />
                                        {filteredOptions[index].airportName}
                                    </div>
                                )}
                            </List>
                        ) : (
                            <div className="no-options">No options found</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

CustomDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    isLoading: PropTypes.bool,
    loadMoreData: PropTypes.func,
    searchKey: PropTypes.string.isRequired,
};

export default CustomDropdown;
