import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../assets/css/FormComponent.css";
import { getAllCountryData, searchHotels } from "./HotelService";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "./HotelCustomSelect";

const HotelBookingForm = () => {
    const navigate = useNavigate();
    const today = new Date().toISOString().split("T")[0];

    // Form data state
    const [formData, setFormData] = useState({
        checkIn: '',
        checkOut: '',
        hotelCode: '',
        guestNationality: 'IN',
        paxRooms: [{ adults: 1, children: 0, childrenAges: [] }],
        responseTime: 23.0,
        isDetailedResponse: true,
        filters: { refundable: false, noOfRooms: 1, mealType: 0, orderBy: 0, starRating: 0, hotelName: '' }
    });

    // State for managing hotels and loading
    const [loading, setLoading] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [loadingHotels, setLoadingHotels] = useState(false);
    const [offset, setOffset] = useState(0);
    const limit = 1000000;

    // Fetch initial hotels data
    useEffect(() => {
        loadHotels();
    }, []);

    // Fetch more hotels data
    const loadHotels = async (newOffset = 0) => {
        setLoadingHotels(true);
        try {
            const hotelData = await getAllCountryData(newOffset, limit);
            console.log("This is final Data:", hotelData);
            setHotels((prevHotels) => [...prevHotels, ...hotelData]);
            setOffset(newOffset + limit);
        } catch (error) {
            console.error("Error fetching hotel data:", error);
        } finally {
            setLoadingHotels(false);
        }
    };

    // Handle form field changes
    const handleChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    // Handle nested field changes (e.g., paxRooms and filters)
    const handleNestedChange = (key, nestedKey, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: { ...prevData[key], [nestedKey]: value },
        }));
    };

    // Form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        const requestData = {
            CheckIn: formData.checkIn,
            CheckOut: formData.checkOut,
            HotelCodes: formData.hotelCode,
            GuestNationality: formData.guestNationality,
            PaxRooms: formData.paxRooms,
            ResponseTime: formData.responseTime,
            IsDetailedResponse: formData.isDetailedResponse,
            Filters: formData.filters,
        };

        console.log("Request Data:", requestData);
        setLoading(true);

        searchHotels(requestData)
            .then((result) => {
                console.log("API Response:", result);
                navigate("/hotel-results", { state: { hotels: result } });
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="booking-sec">
            <div className="container">
                <div className="booking-form ajax-contact">
                    <div className="form-container">
                        {loading && <Loader />}
                        <form className="responsive-form" onSubmit={handleSubmit}>
                            {/* Check-In and Check-Out Dates */}
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="checkIn">Check-In Date</label>
                                    <input
                                        type="date"
                                        id="checkIn"
                                        value={formData.checkIn}
                                        onChange={(e) => handleChange("checkIn", e.target.value)}
                                        required
                                        min={today}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="checkOut">Check-Out Date</label>
                                    <input
                                        type="date"
                                        id="checkOut"
                                        value={formData.checkOut}
                                        onChange={(e) => handleChange("checkOut", e.target.value)}
                                        required
                                        min={formData.checkIn || today}
                                    />
                                </div>
                            </div>

                            {/* Dropdown for Hotels (custom dropdown) */}
                            <div className="form-row">
                                <div className="form-group">
                                    <CustomDropdown
                                        options={hotels}
                                        value={formData.hotelCode}
                                        onChange={(value) => handleChange("hotelCode", value)}
                                        label="Hotel Code"
                                        isLoading={loadingHotels}
                                        loadMoreData={() => loadHotels(offset)}
                                        searchKey="hotelName"  // Ensure "hotelName" is correct
                                    />
                                </div>
                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="hotelCode">Hotel Code</label>*/}
                                {/*    <input*/}
                                {/*        type="text"*/}
                                {/*        id="hotelCode"*/}
                                {/*        value={formData.hotelCode}*/}
                                {/*        onChange={(e) => handleChange("hotelCode", e.target.value)}*/}
                                {/*        min="0"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className="form-group">
                                    <label htmlFor="guestNationality">Guest Nationality</label>
                                    <input
                                        type="text"
                                        id="guestNationality"
                                        value={formData.guestNationality}
                                        onChange={(e) => handleChange("guestNationality", e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Pax Rooms Information */}
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="adults">Adults</label>
                                    <input
                                        type="number"
                                        id="adults"
                                        value={formData.paxRooms[0].adults}
                                        onChange={(e) => handleNestedChange("paxRooms", "adults", e.target.value)}
                                        min="1"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="children">Children</label>
                                    <input
                                        type="number"
                                        id="children"
                                        value={formData.paxRooms[0].children}
                                        onChange={(e) => handleNestedChange("paxRooms", "children", e.target.value)}
                                        min="0"
                                    />
                                </div>
                            </div>

                            {/* Filter for Star Rating */}
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="starRating">Star Rating</label>
                                    <select
                                        id="starRating"
                                        value={formData.filters.starRating}
                                        onChange={(e) => handleNestedChange("filters", "starRating", e.target.value)}
                                    >
                                        <option value="0">Any</option>
                                        <option value="3">3 Stars</option>
                                        <option value="4">4 Stars</option>
                                        <option value="5">5 Stars</option>
                                    </select>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="form-button col-md-12 col-lg-auto">
                                <button className="th-btn" type="submit">
                                    <img src="/assets/img/icon/search.svg" alt="" /> Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

HotelBookingForm.propTypes = {
    hotels: PropTypes.array,
    loading: PropTypes.bool,
};

export default HotelBookingForm;
