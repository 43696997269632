import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slider,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Header from "../../Header";
import Footer from "../../Footer";
import './FlightResult.css';
import useExternalScripts from "../../hooks/useExternalScripts.js";

const FlightResult = () => {
    useExternalScripts();

    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [priceRange, setPriceRange] = useState([0, 100000]); // Example range
    const [selectedAirline, setSelectedAirline] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [filteredFlights, setFilteredFlights] = useState([]);

    // Access the flights data or error message from the location state
    const flights = location.state?.flights || {};
    const traceId = flights.Response?.TraceId;
    if (traceId) {
        localStorage.setItem("traceId", traceId);
    }

    const errorCode = flights.Response?.Error?.ErrorCode;
    const errorMessage = flights.Response?.Error?.ErrorMessage;

    useEffect(() => {
        if (errorMessage) {
            setOpen(true);
        }
    }, [errorMessage]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleGoBack = () => {
        handleClose();
        navigate('/');
    };

    const handleFlightDetailsClick = (flight, index) => {
        console.log("Flight Result Data", flight);
        navigate('/flight-details', { state: { flight, index } });
    };

    // Prepare unique airline names for the dropdown
    const airlineNames = Array.from(new Set(flights.Response?.Results?.flat().map(result =>
        result.Segments?.[0]?.[0]?.Airline?.AirlineName
    ))).filter(Boolean);

    // Filter flights based on price, airline, and time
    useEffect(() => {
        if (flights.Response?.Results) {
            const allFlights = flights.Response.Results.flat();
            const filtered = allFlights.filter(result => {
                const fare = parseFloat(result.Fare?.PublishedFare) || 0;
                const depTime = new Date(result.Segments?.[0]?.[0]?.Origin?.DepTime);
                const hours = depTime.getUTCHours();
                const isTimeValid =
                    (selectedTime === 'morning' && hours >= 6 && hours < 12) ||
                    (selectedTime === 'afternoon' && hours >= 12 && hours < 18) ||
                    (selectedTime === 'night' && (hours >= 18 || hours < 6)) ||
                    selectedTime === ''; // Include all times if no selection

                return fare >= priceRange[0] && fare <= priceRange[1] &&
                    (selectedAirline === '' || result.Segments?.[0]?.[0]?.Airline?.AirlineName === selectedAirline) &&
                    isTimeValid;
            });
            setFilteredFlights(filtered);
        }
    }, [priceRange, selectedAirline, selectedTime, flights]);

    return (
        <>
            <Header />
            <div className="layout">
                <div className="sidebar">
                    <h2>Filters</h2>
                    <hr/>
                    <h6>Filter By Price</h6>
                    <Slider
                        value={priceRange}
                        onChange={(e, newValue) => setPriceRange(newValue)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100000} // Example max price
                    />
                    <div>
                        <span>Min Price: {priceRange[0]} Rs</span><br/>
                        <span>Max Price: {priceRange[1]} Rs</span>
                    </div>

                    <hr/>
                    <h6>Filter By Airline</h6>
                    <FormControl fullWidth>
                        <InputLabel id="airline-select-label">Select Airline</InputLabel>
                        <Select
                            labelId="airline-select-label"
                            value={selectedAirline}
                            onChange={(e) => setSelectedAirline(e.target.value)}
                        >
                            <MenuItem value="">All Airlines</MenuItem>
                            {airlineNames.map((airline, index) => (
                                <MenuItem key={index} value={airline}>
                                    {airline}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <hr/>

                    <h6>Filter By Time</h6>
                    <FormControl fullWidth>
                        <InputLabel id="time-select-label">Select Time</InputLabel>
                        <Select
                            labelId="time-select-label"
                            value={selectedTime}
                            onChange={(e) => setSelectedTime(e.target.value)}
                        >
                            <MenuItem value="">All Times</MenuItem>
                            <MenuItem value="morning">Morning (6 AM - 12 PM)</MenuItem>
                            <MenuItem value="afternoon">Afternoon (12 PM - 6 PM)</MenuItem>
                            <MenuItem value="night">Night (6 PM - 6 AM)</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="content">
                    <div className="flight-result-container">
                        {filteredFlights.length > 0 ? (
                            <div className="response-container">
                                {filteredFlights.map((result, resultIndex) => (
                                    <div key={resultIndex} className="flight-card">
                                        <div className="airline-info">
                                            {/*<img*/}
                                            {/*    src={result.Airline?.LogoUrl || '/assets/img/akashair.png'}*/}
                                            {/*    alt={result.Segments?.[0]?.[0]?.Airline?.AirlineName || 'Airline'}*/}
                                            {/*    className="airline-logo"*/}
                                            {/*/>*/}
                                            <img
                                                src={
                                                    (() => {
                                                        const airlineName = result.Segments?.[0]?.[0]?.Airline?.AirlineName || 'default';
                                                        switch (airlineName.toLowerCase()) {
                                                            case 'air india':
                                                                return '/assets/img/airindia.png';
                                                            case 'vistara':
                                                                return '/assets/img/vistara.jpeg';
                                                            case 'spicejet':
                                                                return '/assets/img/spicejet.jpg';
                                                            case 'akasa air':
                                                                return '/assets/img/akashair.png';
                                                            case 'indigo':
                                                                return '/assets/img/indigo.png';
                                                            default:
                                                                return '/assets/img/default.png'; // Use a default image if the airline is not recognized
                                                        }
                                                    })()
                                                }
                                                alt={result.Segments?.[0]?.[0]?.Airline?.AirlineName || 'Airline'}
                                                className="airline-logo"
                                            />
                                            <div>
                                                <h5>
                                                    {result.Segments?.[0]?.[0]?.Airline?.AirlineName || 'Airline'}
                                                </h5>
                                                <span
                                                    className={`refund-tag ${result.IsRefundable ? 'refundable' : 'non-refundable'}`}>
                                                    {result.IsRefundable ? 'Refundable' : 'Non-refundable'}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="flight-info">
                                            <span>{result.Segments?.[0]?.[0]?.Origin?.DepTime || '00:00'}</span><br/>
                                            <span>{result.Segments?.[0]?.[0]?.Origin?.Airport?.CityName || 'City'}</span>
                                        </div>

                                        <div className="flight-time">
                                            <span
                                                className="duration">{result.Segments?.[0]?.[0]?.Duration + ' minutes' || '0 minutes'}</span>
                                            <div className="dashed-line"></div>
                                            <span
                                                className="non-stop">{result.Segments?.[0]?.[0]?.StopOver ? 'Connecting' : 'Non-stop'}</span>
                                        </div>

                                        <div className="flight-info">
                                            <span>{result.Segments?.[0]?.[0]?.Destination?.ArrTime || '00:00'}</span><br />
                                            <span>{result.Segments?.[0]?.[0]?.Destination?.Airport?.CityName || 'City'}</span>
                                        </div>

                                        <div className="price-info">
                                            <span className="cheapest-badge">
                                                {result.IsCheapest ? 'Cheapest' : ''}
                                            </span><br />
                                            <p className="price">
                                                {result.Fare?.PublishedFare || 'N/A'} Rs
                                            </p>
                                            <p className="business-class">
                                                {result.Segments?.[0]?.[0]?.Airline?.FareClass || 'Class'}
                                            </p>
                                            <p className="business-class">
                                                {result.IsLCC ? 'LCC' : 'NON-LCC'}
                                            </p>
                                            <button
                                                className="flight-details-btn"
                                                onClick={() => handleFlightDetailsClick(result, resultIndex)}
                                            >
                                                Flight Details
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>No flights available with the selected filters.</div>
                        )}

                        {/* Dialog Component for Error Display */}
                        <Dialog
                            fullScreen={fullScreen}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="error-dialog-title"
                        >
                            <DialogTitle id="error-dialog-title">Error Occurred</DialogTitle>
                            <DialogContent>
                                <h3>{errorMessage}</h3>
                                <p>Session ID: {traceId}</p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleGoBack} color="primary">
                                    Go Back
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FlightResult;
