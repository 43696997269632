const Server_BASE_URL = process.env.REACT_APP_SERVER_BASE;


// Helper function to get LocalStorage data with default fallbacks
function getLocalStorageData() {
    const tokenId = localStorage.getItem('tokenId') || '';
    const traceId = localStorage.getItem('traceId') || '';
    const resultIndex = localStorage.getItem('resultIndex') || '';

    return {
        EndUserIp: '192.168.10.10', // Fixed IP address
        TokenId: tokenId,
        TraceId: traceId,
        ResultIndex: resultIndex,
    };
}

// General function to handle all API requests
async function sendRequest(endpoint, method = 'GET', data = null) {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(`${Server_BASE_URL}${endpoint}`, options);

        if (!response.ok) {
            const errorText = await response.text(); // Capture response text
            throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
        }

        return await response.json();
    } catch (error) {
        console.error(`Error making ${method} request to ${endpoint}:`, error);
        throw error;
    }
}

// Hotel-related API calls
export const searchHotels = (data) => sendRequest('/api/send/list', 'POST', data);
export const getAllCountryData = async (offset, limit) => {
    const response = await sendRequest(`/api/hotels/getallcountydata?offset=${offset}&limit=${limit}`);
    return response;
};
