    const Server_BASE_URL = process.env.REACT_APP_SERVER_BASE;

// Helper function to get LocalStorage data with default fallbacks
function getLocalStorageData() {
    const tokenId = localStorage.getItem('tokenId') || '';
    const traceId = localStorage.getItem('traceId') || '';
    const resultIndex = localStorage.getItem('resultIndex') || '';

    return {
        EndUserIp: '192.168.10.10', // Fixed IP address
        TokenId: tokenId,
        TraceId: traceId,
        ResultIndex: resultIndex,
    };
}

// General function to handle all API requests
async function sendRequest(endpoint, method = 'GET', data = null) {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(`${Server_BASE_URL}${endpoint}`, options);

        if (!response.ok) {
            const errorText = await response.text(); // Capture response text
            throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
        }

        return await response.json();
    } catch (error) {
        console.error(`Error making ${method} request to ${endpoint}:`, error);
        throw error;
    }
}

// Flight-related API calls
export const searchFlights = (data) => sendRequest('/api/send', 'POST', data);

export const getAirport = () => sendRequest('/api/airports/getall');

export const getFareRule = () => sendRequest('/api/farerule', 'POST', getLocalStorageData());

export const getFareQuote = () => sendRequest('/api/farequote', 'POST', getLocalStorageData());

// Flight booking API calls
export const BookFlight = (data) => sendRequest('/api/booking/bookNonLccFlight', 'POST', data);

export const TicketFlight = (data) => sendRequest('/api/booking/bookLccFlight', 'POST', data);

// Payment initiation and handling
export async function initiatePayment(data) {
    const response = await fetch(`${Server_BASE_URL}/api/payment/processPayment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseText = await response.text();

    // Insert the received HTML into the document to allow form submission
    const parser = new DOMParser();
    const doc = parser.parseFromString(responseText, 'text/html');
    const form = doc.querySelector('form');

    if (!form) {
        throw new Error('Form not found in the response.');
    }

    document.body.appendChild(form); // Append the form to the document body
    form.submit(); // Submit the form
}

export const auth = () => sendRequest(`/api/auth`, 'POST');
